import React, { useEffect, useState } from 'react'
import HomePageService from '../../services/HomePageService'
import { Link, useNavigate } from 'react-router-dom'
import {
  HomeWrapper,
  StyledList,
  StyledListElement,
  SectionBar,
  SectionWrapper,
  StyledButton,
  CenterBox,
  SectionPagination,
  DetailWrapper,
} from './HomePage.styles'

import { AiOutlineFile } from 'react-icons/ai'
import { BsBuilding } from 'react-icons/bs'
import { TailSpin } from 'react-loader-spinner'
import { useCookies } from 'react-cookie'

export const HomePage = () => {
  const [liquidityApplications, setLiquidityApplications] = useState([])
  const [investmentApplications, setInvestmentApplications] = useState([])
  const [companies, setCompanies] = useState([])
  const [isManager, setIsManager] = useState(false)
  const [cookies] = useCookies()
  // eslint-disable-next-line no-unused-vars
  const [liquidityApplicationLinks, setLiquidityApplicationLinks] = useState({
    prev: null,
    next: null,
  })
  const [investmentApplicationLinks, setInvestmentApplicationLinks] = useState({
    prev: null,
    next: null,
  })
  // eslint-disable-next-line no-unused-vars
  const [companiesLinks, setCompaniesLinks] = useState({
    prev: null,
    next: null,
  })

  const [companySpinner, setCompanySpinner] = useState(true)
  const [liquidityApplicationSpinner, setLiquidityApplicationSpinner] =
    useState(true)
  const [investmentApplicationSpinner, setInvestmentApplicationSpinner] =
    useState(true)

  let navigate = useNavigate()

  useEffect(() => {
    setCompanySpinner(true)
    setLiquidityApplicationSpinner(true)
    setInvestmentApplicationSpinner(true)
    Promise.all([
      HomePageService.getCompanies(null, cookies),
      HomePageService.getFiledApplications(null, true, true, false, cookies),
      HomePageService.getFiledApplications(null, true, false, false, cookies),
    ])
      .then(function (apiResults) {
        setPageBody(apiResults, true)
      })
      .catch((error) => {
        console.log(error)
        Promise.all([
          HomePageService.getCompanies(null, cookies),
          HomePageService.getFiledApplications(
            null,
            false,
            true,
            false,
            cookies
          ),
          HomePageService.getFiledApplications(
            null,
            false,
            false,
            false,
            cookies
          ),
        ])
          .then(function (apiResults) {
            console.log('second')
            setPageBody(apiResults, false)
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 401) {
              navigate('/login')
            } else {
              console.log(error)
            }
          })
      })
  }, [])

  const setPageBody = (apiResults, isManager) => {
    setIsManager(isManager)
    setCompanies(apiResults[0].results)
    setCompaniesLinks({
      prev: apiResults[0]['previous'],
      next: apiResults[0]['next'],
    })
    setLiquidityApplications(apiResults[1].results)
    setLiquidityApplicationLinks({
      prev: apiResults[1]['previous'],
      next: apiResults[1]['next'],
    })
    setInvestmentApplications(apiResults[2].results)
    setInvestmentApplicationLinks({
      prev: apiResults[2]['previous'],
      next: apiResults[2]['next'],
    })
    setCompanySpinner(false)
    setLiquidityApplicationSpinner(false)
    setInvestmentApplicationSpinner(false)
  }

  const changeCompaniesPage = (pageUrl) => {
    setCompanySpinner(true)
    HomePageService.getCompanies(pageUrl, cookies).then((result) => {
      setCompanies(result.results)
      setCompaniesLinks({
        prev: result['previous'],
        next: result['next'],
      })
      setCompanySpinner(false)
    })
  }

  const changeLiquidityApplicationsPage = (pageUrl) => {
    setLiquidityApplicationSpinner(true)
    HomePageService.getFiledApplications(
      pageUrl,
      isManager,
      true,
      false,
      cookies
    ).then((result) => {
      setLiquidityApplications(result.results)
      setLiquidityApplicationLinks({
        prev: result['previous'],
        next: result['next'],
      })
      setLiquidityApplicationSpinner(false)
    })
  }

  const changeInvestmentApplicationsPage = (pageUrl) => {
    setInvestmentApplicationSpinner(true)
    HomePageService.getFiledApplications(
      pageUrl,
      isManager,
      false,
      false,
      cookies
    ).then((result) => {
      setInvestmentApplications(result.results)
      setInvestmentApplicationLinks({
        prev: result['previous'],
        next: result['next'],
      })
      setInvestmentApplicationSpinner(false)
    })
  }

  return (
    <HomeWrapper className="block">
      <DetailWrapper>
        <SectionBar>
          Wnioski o Pożyczki Płynnościowe <AiOutlineFile />{' '}
        </SectionBar>
        {liquidityApplicationSpinner ? (
          <CenterBox>
            <TailSpin />
          </CenterBox>
        ) : (
          <StyledList>
            {liquidityApplications.map((application) => (
              <StyledListElement key={application.application_id}>
                <div className="field is-grouped">
                  <p>
                    {application.company_name} - {application.type} (
                    {application.is_worker_rated
                      ? 'Ocenione przez pracownika'
                      : 'Nieocenione przez pracownika'}
                    )
                  </p>
                </div>
                <p>
                  <div className="field is-grouped">
                    <p className="control">
                      <StyledButton className="button  is-primary is-small">
                        <Link
                          to={`/syntheticEvaluation/${application.application_id}?m=false`}
                        >
                          Analiza Pracownika
                        </Link>
                      </StyledButton>
                      {isManager ? (
                        <StyledButton className="button  is-warning is-small">
                          <Link
                            to={`/syntheticEvaluation/${application.application_id}?m=true`}
                          >
                            Analiza Kierownika
                          </Link>
                        </StyledButton>
                      ) : null}
                    </p>
                  </div>
                </p>
              </StyledListElement>
            ))}
          </StyledList>
        )}
        <SectionPagination>
          <SectionPagination>
            <button
              className="button is-outlined"
              onClick={() =>
                changeLiquidityApplicationsPage(liquidityApplicationLinks.prev)
              }
              disabled={liquidityApplicationLinks.prev === null}
            >
              Poprzednia strona
            </button>
            <button
              className="button is-outlined"
              onClick={() =>
                changeLiquidityApplicationsPage(liquidityApplicationLinks.next)
              }
              disabled={liquidityApplicationLinks.next === null}
            >
              Następna strona
            </button>
          </SectionPagination>
        </SectionPagination>
      </DetailWrapper>
      <DetailWrapper>
        <SectionBar>Dostępne Wnioski o Pożyczki Inwestycyjne</SectionBar>
        {investmentApplicationSpinner ? (
          <CenterBox>
            <TailSpin />
          </CenterBox>
        ) : (
          <StyledList>
            {investmentApplications.map((application) => (
              <StyledListElement key={application.application_id}>
                <div className="field is-grouped">
                  <p>
                    {application.company_name} - {application.type} (
                    {application.is_worker_rated
                      ? 'Ocenione przez pracownika'
                      : 'Nieocenione przez pracownika'}
                    )
                  </p>
                </div>
                <p>
                  <div className="field is-grouped">
                    <p className="control">
                      <StyledButton className="button  is-primary is-small">
                        <Link
                          to={`/syntheticEvaluation/${application.application_id}?m=false`}
                        >
                          Analiza Pracownika
                        </Link>
                      </StyledButton>
                      {isManager ? (
                        <StyledButton className="button  is-warning is-small">
                          <Link
                            to={`/syntheticEvaluation/${application.application_id}?m=true`}
                          >
                            Analiza Kierownika
                          </Link>
                        </StyledButton>
                      ) : null}
                    </p>
                  </div>
                </p>
              </StyledListElement>
            ))}
          </StyledList>
        )}
        <SectionPagination>
          <SectionPagination>
            <button
              className="button is-outlined"
              onClick={() =>
                changeInvestmentApplicationsPage(
                  investmentApplicationLinks.prev
                )
              }
              disabled={investmentApplicationLinks.prev === null}
            >
              Poprzednia strona
            </button>
            <button
              className="button is-outlined"
              onClick={() =>
                changeInvestmentApplicationsPage(
                  investmentApplicationLinks.next
                )
              }
              disabled={investmentApplicationLinks.next === null}
            >
              Następna strona
            </button>
          </SectionPagination>
        </SectionPagination>
      </DetailWrapper>
      <SectionWrapper>
        <SectionBar>
          Firmy <BsBuilding />
        </SectionBar>
        {companySpinner ? (
          <CenterBox>
            <TailSpin />
          </CenterBox>
        ) : (
          <StyledList>
            {companies.map((company) => (
              <StyledListElement key={company.id}>
                <Link to={`/companies/${company.id}/`}>
                  {company.company_name}
                </Link>
              </StyledListElement>
            ))}
          </StyledList>
        )}
        <SectionPagination>
          <button
            className="button is-outlined"
            onClick={() => changeCompaniesPage(companiesLinks.prev)}
            disabled={companiesLinks.prev === null}
          >
            Poprzednia strona
          </button>
          <button
            className="button is-outlined"
            onClick={() => changeCompaniesPage(companiesLinks.next)}
            disabled={companiesLinks.next === null}
          >
            Następna strona
          </button>
        </SectionPagination>
      </SectionWrapper>
    </HomeWrapper>
  )
}
