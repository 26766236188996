import React, { useState } from 'react'
import {
  StyledCenteredTableCell,
  StyledTableCell,
} from '../ApplicationsView/ApplicationsView.styles'

export const InterProcTableRow = ({ coreData, setCoreData }) => {
  const [newItem, setNewItem] = useState({})

  const submit = () => {
    if (newItem['unit'] === undefined) {
      newItem['unit'] = 'h'
    }
    coreData.push(newItem)
    setCoreData([...coreData])
    setNewItem({})
  }

  return (
    <tr>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                defaultValue={newItem['activity']}
                className="input"
                type="text"
                placeholder="Nazwa i Czynność"
                onChange={(event) => {
                  newItem['activity'] = event.target.value
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="select">
          <select
            defaultValue="h"
            onChange={(event) => {
              newItem['unit'] = event.target.value
              setNewItem(newItem)
            }}
          >
            <option value="min">Minuta</option>
            <option value="h">Godzina</option>
            <option value="d">Dzień</option>
            <option value="w">Tydzień</option>
          </select>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                defaultValue={newItem['duration']}
                className="input"
                type="number"
                placeholder="Liczba"
                onChange={(event) => {
                  newItem['duration'] = parseInt(event.target.value)
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledCenteredTableCell>
        <button className="button is-success" onClick={submit}>
          Dodaj
        </button>
      </StyledCenteredTableCell>
    </tr>
  )
}
