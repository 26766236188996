import { isNil } from 'lodash'

export default class TranslateService {
  constructor() {
    this.translateData = require('../assets/translate/translate.json')
  }

  instant(translateKey) {
    if (!isNil(this.translateData)) {
      if (translateKey in this.translateData) {
        return this.translateData[translateKey]
      }
      return translateKey
    }
    return translateKey
  }
}
