import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Constants } from '../../utils/Constants'

import {
  Form,
  StyledTable,
  StyledTableCell,
  SectionBar,
  ContentWrapper,
} from './SettingsView.styles'
import { useCookies } from 'react-cookie'
import { getAuthHeaders } from '../../services/AuthService'
import { useNavigate } from 'react-router-dom'

export const SettingsView = () => {
  const [settings, setSettings] = useState([])
  const [isSaving, setIsSaving] = useState(true)
  const [cookies] = useCookies()

  const navigate = useNavigate()

  useEffect(() => {
    updateBody()
  }, [])

  const updateBody = () => {
    axios
      .get(`${Constants.GLOBAL_API_URL}/getSettingsTypes/`, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        setSettings(result.data)
        setIsSaving(false)
      })
      .catch((error) => {
        console.log(error)
        navigate('/home')
      })
  }

  const synchroniseData = (event) => {
    event.preventDefault()
    setIsSaving(true)
    axios
      .get(`${Constants.GLOBAL_API_URL}/synchronise/`, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then(() => updateBody())
  }

  const submitData = (event) => {
    setIsSaving(true)
    event.preventDefault()
    axios
      .put(`${Constants.GLOBAL_API_URL}/updateSettingsTypes/`, settings, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        setSettings(result.data)
        setIsSaving(false)
      })
      .catch(() => {
        setIsSaving(false)
        alert('Wystąpił błąd zapisu')
      })
  }

  return (
    <Form onSubmit={submitData}>
      <SectionBar>Ustawienia</SectionBar>
      <ContentWrapper>
        <StyledTable>
          <thead>
            <th>Nazwa Typu Wniosku</th>
            <th>Ustawienia</th>
          </thead>
          <tbody>
            {settings.map((setting) => {
              return (
                <tr key={setting.application_type}>
                  <StyledTableCell>{setting.application_type}</StyledTableCell>
                  <StyledTableCell>
                    <div className="control">
                      <label className="radio">
                        <input
                          checked={setting.is_liquidity === true}
                          name={`setSetting_${setting.application_type}`}
                          type="radio"
                          onChange={() => {
                            setting.is_liquidity = true
                            setting.is_investment = false
                            setting.is_other = false
                            settings[settings.indexOf(setting)] = setting
                            setSettings([...settings])
                          }}
                        />
                        Pożyczka Płynnościowa
                      </label>
                      <label className="radio">
                        <input
                          checked={setting.is_investment === true}
                          name={`setSetting_${setting.application_type}`}
                          type="radio"
                          onChange={() => {
                            setting.is_liquidity = false
                            setting.is_investment = true
                            setting.is_other = false
                            settings[settings.indexOf(setting)] = setting
                            setSettings([...settings])
                          }}
                        />
                        Pożyczka Inwestycyjna
                      </label>
                      <label className="radio">
                        <input
                          checked={setting.is_other === true}
                          name={`setSetting_${setting.application_type}`}
                          type="radio"
                          onChange={() => {
                            setting.is_liquidity = false
                            setting.is_investment = false
                            setting.is_other = true
                            settings[settings.indexOf(setting)] = setting
                            setSettings([...settings])
                          }}
                        />
                        Inny Typ Pożyczki
                      </label>
                    </div>
                  </StyledTableCell>
                </tr>
              )
            })}
          </tbody>
        </StyledTable>
        <button
          className={`button is-primary ${isSaving ? 'is-loading' : null}`}
          onClick={submitData}
          disabled={false}
        >
          Zatwierdź
        </button>
        <button className="button is-warning" onClick={synchroniseData}>
          Synchronizuj Dane
        </button>
      </ContentWrapper>
    </Form>
  )
}
