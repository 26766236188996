import React from 'react'
import {
  StyledTable,
  StyledTableCell,
} from '../SubjectiveFactorsTable/SubjectiveFactorsTable.styles'

export const ManagerSummaryTable = ({ summary }) => {
  const safeguradsLevels = { 1: 'Wysoki', 2: 'Standardowy', 3: 'Niski' }
  return (
    <div>
      <StyledTable>
        <thead>
          <tr>
            <th>1 Oceniający - Punktacja</th>
            <th>2 Oceniający - Punktacja</th>
            <th>Średnia Przyznanych Punktów</th>
            <th>1 Oceniający - Poziom Zabezpieczeń</th>
            <th>2 Oceniający - Poziom Zabezpieczeń</th>
            <th>Marża Pożyczki</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell>
            {summary.other_evaluator_points_sum}
          </StyledTableCell>
          <StyledTableCell>{summary.points_sum}</StyledTableCell>
          <StyledTableCell>{summary.points_average}</StyledTableCell>
          <StyledTableCell>
            {
              safeguradsLevels[
                parseInt(summary.other_evaluator_safeguards_level)
              ]
            }
          </StyledTableCell>
          <StyledTableCell>
            {safeguradsLevels[parseInt(summary.safeguards_level)]}
          </StyledTableCell>
          <StyledTableCell>{summary.loan_margin}</StyledTableCell>
        </tr>
      </StyledTable>
    </div>
  )
}
