import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

export const ComWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const StyledLink = styled(Link)`
  width: 20%;
  margin: 10px 5px;
`

export const ComSlider = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
`

export const ComSliderElement = styled.div.attrs()`
  padding: 5px;
  position: relative;
  overflow-y: scroll;
  cursor: pointer;
  width: ${(props) => (props.open ? '45%' : '300px')};
  > div > form {
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  }
  > div > div.columns {
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  }
  transition: ease-out 0.5s;
  height: 100%;
`

export const ComSliderElementIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  font-size: 30px;
  padding: 25px 20px;
  z-index: 99999;
`

const ShowComparision = keyframes`
 from {
  width: auto;
 }
 to {
  width: 1000px;
 }
`

export const ToggleComparision = styled.div`
  animation: ${ShowComparision} 2s linear;
`
