import styled from 'styled-components'

export const Form = styled.form`
  margin: auto auto;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  max-width: 1440px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

export const SectionBar = styled.h1`
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
  background-color: rgba(40, 168, 87, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`

export const StyledTable = styled.table`
  padding: 20px;
  border-collapse: collapse;
  border: 2px solid black;
  width: 100%;
  font-size: 15px;

  > thead > th {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    padding: 8px;
  }

  textarea {
    height: 100%;
    width: 100%;
  }
`

export const StyledTableCell = styled.td`
  border: 1px solid black;
  padding: 8px;
  vertical-align: middle;

  > div > label {
    > input {
      margin: 10px;
    }
  }
`

export const ContentWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    width: 200px;
    padding: 10px;
    margin-top: 10px;
  }
`
