import { ApplicationsWrapper, Wrapper } from './Root.styles'
import { Navigation } from 'components/Navigation/Navigation'
import { ApplicationsSyntheticEvaluation } from './ApplicationsSyntheticEvaluation/ApplicationsSyntheticEvaluation'
import { ApplicationsView } from './ApplicationsView/ApplicationsView'
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom'
import { HomePage } from './home/HomePage'
import { LoginPage } from './login/LoginPage'
import { CompanyDetail } from './CompanyDetails/CompanyDetail'
import { NewMarketAnalysis } from './MarketAnalysis/MarketAnalysis'
import { InternalProcesses } from './InternalProcesses /InternalProcesses'
import { CompanyComparison } from './CompanyComparison/CompanyComparison'
import { AddCompany } from './AddCompany/AddCompany'
import { SettingsView } from './settings/SettingsView'
import { CookiesProvider } from 'react-cookie'
import { ApplicationsArchive } from './ApplicationsView/ApplicationsArchive'
import { useState } from 'react'

const Root = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  return (
    <CookiesProvider>
      <BrowserRouter basename="/analiza">
        <Wrapper>
          <Navigation isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route
              path="/login"
              element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/dodaj-firme" element={<AddCompany />} />
            <Route
              path="/syntheticEvaluation/:application_id/"
              element={
                <ApplicationsWrapper>
                  <ApplicationsView />
                  <ApplicationsSyntheticEvaluation />
                </ApplicationsWrapper>
              }
            ></Route>
            <Route
              path="/companies/:companyId"
              element={<CompanyDetail />}
            ></Route>
            <Route
              path="/marketAnalysis/:marketAnalysisId/"
              element={<NewMarketAnalysis />}
            ></Route>
            <Route
              path="/marketAnalysis/new/:companyID/"
              element={<NewMarketAnalysis isNew={true} />}
            ></Route>
            <Route
              path="/internal_processes/:internalProcessId"
              element={<InternalProcesses />}
            />
            <Route
              path="/internal_processes/new/:companyId"
              element={<InternalProcesses isNew={true} />}
            />
            <Route
              path="/marketAnalysis/compare/:mainCompanyId"
              element={<CompanyComparison />}
            />
            <Route
              path="/internalProcesses/compare/:mainCompanyId"
              element={<CompanyComparison isMarketAnalysis={false} />}
            />
            <Route path="/settings" element={<SettingsView />} />
            <Route path="/archive" element={<ApplicationsArchive />} />
            <Route path="*" element={<Navigate to="home" />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </CookiesProvider>
  )
}

export default Root
