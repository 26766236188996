import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 10px auto;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  max-width: 1440px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.6);
`

export const SectionBar = styled.h1`
  position: sticky;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
  background-color: rgba(40, 168, 87, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  border: 2px solid black;
  width: 100%;
  font-size: 15px;

  > tr > th {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    padding: 5px;
  }

  > tr > td > tr > th {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  > tr > td > tr > td {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  textarea {
    height: 100%;
    width: 100%;
  }
`

export const CenterBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 1440px;
  height: 93vh;
  align-items: center;
`
