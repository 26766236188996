import React, { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import {
  Wrapper,
  SectionBar,
  SelectWrapper,
  ContentWrapper,
  ButtonGroup,
  CompaniesList,
} from './CompareForm.styles'
import { useCookies } from 'react-cookie'

export const CompareForm = ({
  confirmSelection,
  mainCompanyId,
  selectedCompanies,
  setSelectedCompanies,
  downloadDataFunction,
}) => {
  const [availableCompanies, setAvailableCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)

  const [cookies] = useCookies()

  useEffect(() => {
    if (!isNil(mainCompanyId)) {
      downloadDataFunction(mainCompanyId, true, cookies).then((result) => {
        setAvailableCompanies(result)
      })
    }
  }, [])

  const selectCompany = () => {
    console.log(selectedCompany)
    if (selectedCompany !== null) {
      const wantedCompany = availableCompanies.filter((company) => {
        return company.company_id === parseInt(selectedCompany)
      })[0]
      if (wantedCompany === null) return
      availableCompanies.splice(availableCompanies.indexOf(wantedCompany), 1)
      setAvailableCompanies([...availableCompanies])
      setSelectedCompany(null)
      selectedCompanies.push(wantedCompany)
      setSelectedCompanies([...selectedCompanies])
    }
  }

  return (
    <Wrapper>
      <SectionBar>Wybierz firmy do porównania</SectionBar>
      <ContentWrapper>
        <SelectWrapper>
          <div className="select">
            <select
              onChange={(event) => {
                setSelectedCompany(event.target.value)
              }}
            >
              <option value={null}>Wybierz Firmę</option>
              {availableCompanies.map((company) => {
                return (
                  <option value={company.company_id} key={company.company_id}>
                    {company.company_name}
                  </option>
                )
              })}
            </select>
          </div>
          <ButtonGroup>
            <button
              className="button is-primary is-light"
              onClick={selectCompany}
            >
              Dodaj
            </button>
          </ButtonGroup>
        </SelectWrapper>

        <CompaniesList>
          <h2>Wybrane Firmy: </h2>
          {selectedCompanies.length > 0 ? (
            <ul>
              {selectedCompanies.map((company) => {
                return <li key={company.company_id}>{company.company_name}</li>
              })}
            </ul>
          ) : null}
        </CompaniesList>
        <button
          className="button"
          disabled={selectedCompanies.length === 0}
          onClick={confirmSelection}
        >
          Porównaj
        </button>
      </ContentWrapper>
    </Wrapper>
  )
}
