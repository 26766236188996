import React, { useEffect, useState } from 'react'
import {
  StyledCenteredTableCell,
  StyledTableCell,
} from '../ApplicationsView/ApplicationsView.styles'
import { DynamicRowForm } from '../../components/forms/DynamicRowForm'
import { GrAdd } from 'react-icons/gr'
import { GiCancel } from 'react-icons/gi'
import { MarketAnalysisFieldNames } from './MarketAnalysisFieldNames'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyDetailService from '../../services/CompanyDetailService'
import { BsFillTrashFill } from 'react-icons/bs'
import { cloneDeep } from 'lodash'
import { StyledButton } from '../ApplicationsSyntheticEvaluation/ApplicationsSyntheticEvaluation.styles'
import { saveAs } from 'file-saver'

import {
  Wrapper,
  SectionBar,
  StyledTable,
  CenterBox,
} from './MarketAnalysis.styles'
import { useForm } from 'react-hook-form'
import { TailSpin } from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { authorize } from '../../services/AuthService'

export const NewMarketAnalysis = ({
  isNew = false,
  isPreview = false,
  forcedAnalysisId = null,
  forcedTitle = null,
}) => {
  const [characteristic, setCharcteristic] = useState({})
  const [suppliers, setSuppliers] = useState([])
  const [recivers, setRecivers] = useState([])
  const [concurency, setConcurency] = useState([])

  const [initialData, setInitialData] = useState([])

  const [editingSuppliers, setEditingSuppliers] = useState(false)
  const [editingRecivers, setEditingRecivers] = useState(false)
  const [editingConcurency, setEditingConcurency] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const { register, handleSubmit } = useForm()

  let { companyID } = useParams()
  const { marketAnalysisId } = useParams()
  const [cookies] = useCookies()
  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    setSpinner(true)
    if (!isNew) {
      let idToDownload
      if (forcedAnalysisId !== null) {
        idToDownload = forcedAnalysisId
      } else if (marketAnalysisId !== undefined || isNaN(marketAnalysisId)) {
        idToDownload = marketAnalysisId
      } else navigate('/home')
      CompanyDetailService.getSingleCompanyMarketAnalysis(
        idToDownload,
        cookies
      ).then((result) => {
        setInitialData(cloneDeep(result))
        companyID = result.company
        setSuppliers(result.suppliers)
        setRecivers(result.receivers)
        setConcurency(result.descriptions)
        setCharcteristic(result.characteristics[0])
        setSpinner(false)
      })
    } else {
      authorize(cookies)
        .then(() => {
          setSpinner(false)
          setIsEditing(true)
        })
        .catch(() => navigate('/login'))
    }
  }, [])

  const handleRevertChanges = () => {
    setSuppliers([...initialData.suppliers])
    setRecivers([...initialData.receivers])
    setCharcteristic(initialData.characteristics[0])
    setConcurency([...initialData.descriptions])
    setIsEditing(false)
    setEditingConcurency(false)
    setEditingSuppliers(false)
    setEditingRecivers(false)
  }

  const createBody = () => {
    setIsSaving(true)
    const requestBody = {
      company: parseInt(companyID),
      characteristics: [characteristic],
      receivers: recivers,
      suppliers: suppliers,
      descriptions: concurency,
    }
    if (isNew) {
      CompanyDetailService.saveCompanyMarketAnalysis(requestBody, cookies)
        .then((result) => {
          navigate(`/marketAnalysis/${result.data.pk}/`)
          setIsEditing(false)
          setIsSaving(false)
          setEditingConcurency(false)
          setEditingSuppliers(false)
          setEditingRecivers(false)
        })
        .catch((error) => {
          setIsSaving(false)
          alert(error)
        })
    } else {
      requestBody['pk'] = parseInt(marketAnalysisId)
      requestBody['company'] = initialData.company
      CompanyDetailService.editCompanyMarketAnalysis(
        requestBody,
        marketAnalysisId,
        cookies
      )
        .then(() => {
          setIsEditing(false)
          setIsSaving(false)
          setEditingConcurency(false)
          setEditingSuppliers(false)
          setEditingRecivers(false)
        })
        .catch(() => {
          setIsSaving(false)
          alert('Upewnij się, że wszystkie pola są wypełnione')
        })
    }
  }

  const downloadReport = () => {
    setIsDownloading(true)
    CompanyDetailService.downloadMarketAnalysis(marketAnalysisId, cookies)
      .then((result) => {
        setIsDownloading(false)
        saveAs(result.data, 'excel-report.xlsx')
      })
      .catch((error) => {
        setIsDownloading(false)
        alert('Błąd pobierania danych')
        console.log(error)
      })
  }

  return (
    <Wrapper>
      <SectionBar>
        {forcedTitle !== null ? forcedTitle : 'Analiza rynkowa'}
      </SectionBar>
      {spinner ? (
        <CenterBox>
          <TailSpin />
        </CenterBox>
      ) : (
        <form onSubmit={handleSubmit(createBody)}>
          <section className="section">
            <h1 className="title">Charakterystyka Przedsiębiorstwa</h1>
            <StyledTable className="table is-bordered is-striped">
              <tr>
                <th>Data rozpoczęcia działalnośći</th>
                <StyledTableCell>
                  {isEditing ? (
                    <div className="control">
                      <input
                        {...register('operationStartDate', {
                          required: 'To pole jest wymagane',
                        })}
                        className="input is-small"
                        readOnly={!isEditing}
                        defaultValue={characteristic['operation_start_date']}
                        type="date"
                        onChange={(value) => {
                          characteristic['operation_start_date'] =
                            value.target.value
                          setCharcteristic(characteristic)
                        }}
                      />
                    </div>
                  ) : (
                    characteristic['operation_start_date']
                  )}
                </StyledTableCell>
              </tr>
              <tr>
                <th>Główny przedmiot prowadzonej działalności gospodarczej</th>
                <StyledTableCell>
                  {isEditing ? (
                    <div className="control">
                      <textarea
                        {...register('operationMainSubject', {
                          required: true,
                        })}
                        className="textarea is-normal"
                        readOnly={!isEditing}
                        defaultValue={characteristic['operation_main_subject']}
                        onChange={(event) => {
                          characteristic['operation_main_subject'] =
                            event.target.value
                          setCharcteristic(characteristic)
                        }}
                      />
                    </div>
                  ) : (
                    characteristic['operation_main_subject']
                  )}
                </StyledTableCell>
              </tr>
              <tr>
                <th>
                  Opis aktualnie prowadzanej działalności gospodarczej, w tym
                  uszczegółowienie głównego przedmiotu działalności
                </th>
                <StyledTableCell>
                  {isEditing ? (
                    <div className="control">
                      <textarea
                        {...register('operationCurrentDescription', {
                          required: true,
                        })}
                        className="textarea is-medium"
                        readOnly={!isEditing}
                        defaultValue={
                          characteristic['operation_current_description']
                        }
                        onChange={(event) => {
                          characteristic['operation_current_description'] =
                            event.target.value
                          setCharcteristic(characteristic)
                        }}
                      />
                    </div>
                  ) : (
                    characteristic['operation_current_description']
                  )}
                </StyledTableCell>
              </tr>
              <tr>
                <th>
                  Miejsca prowadzenia działalności gospodarczej (w przypadku
                  posiadania fili, oddziału proszę podać ich siedziby)
                </th>
                <StyledTableCell>
                  <tr>
                    <th>Główne miejsce prowadzenia działalnośći</th>
                    <th>
                      Dodatkowe miejsce prowadzenia działalności (w tym filie
                      oddziały)
                    </th>
                  </tr>
                  <tr>
                    <StyledTableCell>
                      {isEditing ? (
                        <div className="control">
                          <input
                            {...register('operationMainLocation', {
                              required: true,
                            })}
                            className="input is-medium"
                            readOnly={!isEditing}
                            defaultValue={
                              characteristic['operation_main_location']
                            }
                            type="text"
                            onChange={(event) => {
                              characteristic['operation_main_location'] =
                                event.target.value
                              setCharcteristic(characteristic)
                            }}
                          />
                        </div>
                      ) : (
                        characteristic['operation_main_location']
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {isEditing ? (
                        <div className="control">
                          <input
                            {...register('operationAdditionalLocations', {
                              required: true,
                            })}
                            className="input is-medium"
                            readOnly={!isEditing}
                            defaultValue={
                              characteristic['operation_additional_locations']
                            }
                            type="text"
                            onChange={(event) => {
                              characteristic['operation_additional_locations'] =
                                event.target.value
                              setCharcteristic(characteristic)
                            }}
                          />
                        </div>
                      ) : (
                        characteristic['operation_additional_locations']
                      )}
                    </StyledTableCell>
                  </tr>
                </StyledTableCell>
              </tr>
              <tr>
                <th>Zatrudnienie u wnioskodawcy w pełnych etatach</th>
                <StyledTableCell>
                  <tr>
                    <th>Poprzedni zamknięty rok obrachunkowy</th>
                    <th>Ostatni zamknięty rok obrachunkowy</th>
                    <th>Na dzień dzisiejszy</th>
                  </tr>
                  <tr>
                    <StyledTableCell>
                      {isEditing ? (
                        <div className="control">
                          <input
                            {...register('fullTimeEmployedPrevYear')}
                            className="input is-small"
                            readOnly={!isEditing}
                            defaultValue={
                              characteristic['full_time_employed_prev_year']
                            }
                            type="number"
                            onChange={(event) => {
                              characteristic['full_time_employed_prev_year'] =
                                parseInt(event.target.value)
                              setCharcteristic(characteristic)
                            }}
                          />
                        </div>
                      ) : (
                        characteristic['full_time_employed_prev_year']
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {isEditing ? (
                        <div className="control">
                          <input
                            {...register('fullTimeEmployedLastYear')}
                            className="input is-small"
                            readOnly={!isEditing}
                            defaultValue={
                              characteristic['full_time_employed_last_year']
                            }
                            type="number"
                            onChange={(event) => {
                              characteristic['full_time_employed_last_year'] =
                                parseInt(event.target.value)
                              setCharcteristic(characteristic)
                            }}
                          />
                        </div>
                      ) : (
                        characteristic['full_time_employed_last_year']
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {isEditing ? (
                        <div className="control">
                          <input
                            {...register('fullTimeEmployedCurrentYear')}
                            className="input is-small"
                            readOnly={!isEditing}
                            defaultValue={
                              characteristic['full_time_employed_current_year']
                            }
                            type="number"
                            onChange={(event) => {
                              characteristic[
                                'full_time_employed_current_year'
                              ] = parseInt(event.target.value)
                              setCharcteristic(characteristic)
                            }}
                          />
                        </div>
                      ) : (
                        characteristic['full_time_employed_current_year']
                      )}
                    </StyledTableCell>
                  </tr>
                </StyledTableCell>
              </tr>
              <tr>
                <th>
                  Posiadane uprawnienia np. zezwolenia, licencje, koncesje, itp.
                  (ze wskazaniem terminu ich ważności)
                </th>
                <StyledTableCell>
                  {isEditing ? (
                    <div className="control">
                      <textarea
                        {...register('heldQualifications', { required: true })}
                        className="textarea is-large"
                        readOnly={!isEditing}
                        defaultValue={characteristic['held_qualifications']}
                        onChange={(event) => {
                          characteristic['held_qualifications'] =
                            event.target.value
                          setCharcteristic(characteristic)
                        }}
                      />
                    </div>
                  ) : (
                    characteristic['held_qualifications']
                  )}
                </StyledTableCell>
              </tr>
            </StyledTable>
          </section>
          <section className="section">
            <h1 className="title">Dostawcy</h1>
            <StyledTable>
              <tr>
                <th>Nazwa Dostawcy i Adres</th>
                <th>Produkt/Usługa</th>
                <th>Data Rozpoczęcia Współpracy</th>
                <th>Udział w dostawach</th>
                <th>Sposób i termin zapłaty</th>
                {isEditing ? (
                  <StyledCenteredTableCell className="container">
                    <button
                      hidden={!isEditing}
                      className="button is-text"
                      onClick={(event) => {
                        event.preventDefault()
                        setEditingSuppliers(!editingSuppliers)
                      }}
                    >
                      {editingSuppliers ? <GiCancel /> : <GrAdd />}
                    </button>
                  </StyledCenteredTableCell>
                ) : null}
              </tr>
              {suppliers.map((supplier) => {
                return (
                  <tr key={supplier.supplier_name_address}>
                    <StyledTableCell>
                      {supplier['supplier_name_address']}
                    </StyledTableCell>
                    <StyledTableCell>{supplier['product']}</StyledTableCell>
                    <StyledTableCell>
                      {supplier['start_cooperation_date']}
                    </StyledTableCell>
                    <StyledTableCell>
                      {supplier['supplies_share']}
                    </StyledTableCell>
                    <StyledTableCell>
                      {supplier['type_date_payment']}
                    </StyledTableCell>
                    {isEditing ? (
                      <StyledCenteredTableCell className="container">
                        <button
                          className="button is-text"
                          onClick={(event) => {
                            event.preventDefault()
                            suppliers.splice(suppliers.indexOf(supplier), 1)
                            setSuppliers([...suppliers])
                          }}
                        >
                          <BsFillTrashFill />
                        </button>
                      </StyledCenteredTableCell>
                    ) : null}
                  </tr>
                )
              })}
              {editingSuppliers ? (
                <DynamicRowForm
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  fieldNames={MarketAnalysisFieldNames.suppliers}
                />
              ) : null}
            </StyledTable>
          </section>
          <section className="section">
            <h1 className="title">Odbiorcy</h1>
            <StyledTable>
              <tr>
                <th>Nazwa Odbiorcy i Adres</th>
                <th>Produkt/Usługa</th>
                <th>Data Rozpoczęcia Współpracy</th>
                <th>Udział w dostawach</th>
                <th>Sposób i termin zapłaty</th>
                {isEditing ? (
                  <StyledCenteredTableCell className="container">
                    <button
                      className="button is-text"
                      onClick={(event) => {
                        event.preventDefault()
                        setEditingRecivers(!editingRecivers)
                      }}
                    >
                      {editingRecivers ? <GiCancel /> : <GrAdd />}
                    </button>
                  </StyledCenteredTableCell>
                ) : null}
              </tr>
              {recivers.map((reciver) => {
                return (
                  <tr key={reciver.reciever_name_address}>
                    <StyledTableCell>
                      {reciver[MarketAnalysisFieldNames.recivers[0]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {reciver[MarketAnalysisFieldNames.recivers[1]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {reciver[MarketAnalysisFieldNames.recivers[2]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {reciver[MarketAnalysisFieldNames.recivers[3]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {reciver[MarketAnalysisFieldNames.recivers[4]]}
                    </StyledTableCell>
                    {isEditing ? (
                      <StyledCenteredTableCell className="container">
                        <button
                          className="button is-text"
                          onClick={(event) => {
                            event.preventDefault()
                            recivers.splice(recivers.indexOf(reciver), 1)
                            setRecivers([...recivers])
                          }}
                        >
                          <BsFillTrashFill />
                        </button>
                      </StyledCenteredTableCell>
                    ) : null}
                  </tr>
                )
              })}
              {editingRecivers ? (
                <DynamicRowForm
                  suppliers={recivers}
                  setSuppliers={setRecivers}
                  fieldNames={MarketAnalysisFieldNames.recivers}
                />
              ) : null}
            </StyledTable>
          </section>
          <section className="section">
            <h1 className="title">Opis Konkurencji</h1>
            <StyledTable>
              <tr>
                <th>Nazwa i Adres</th>
                <th>Produkt/Usługa</th>
                <th>Silne Strony</th>
                <th>Słabe Strony</th>
                {isEditing ? (
                  <StyledCenteredTableCell classname="container">
                    <button
                      className="button is-text"
                      onClick={(event) => {
                        event.preventDefault()
                        setEditingConcurency(!editingConcurency)
                      }}
                    >
                      {editingConcurency ? <GiCancel /> : <GrAdd />}
                    </button>
                  </StyledCenteredTableCell>
                ) : null}
              </tr>
              {concurency.map((conc) => {
                return (
                  <tr key={conc.competition_name_address}>
                    <StyledTableCell>
                      {conc[MarketAnalysisFieldNames.descriptions[0]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {conc[MarketAnalysisFieldNames.descriptions[1]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {conc[MarketAnalysisFieldNames.descriptions[2]]}
                    </StyledTableCell>
                    <StyledTableCell>
                      {conc[MarketAnalysisFieldNames.descriptions[3]]}
                    </StyledTableCell>
                    {isEditing ? (
                      <StyledCenteredTableCell className="container">
                        <button
                          className="button is-text"
                          onClick={(event) => {
                            event.preventDefault()
                            concurency.splice(concurency.indexOf(conc), 1)
                            setConcurency([...concurency])
                          }}
                        >
                          <BsFillTrashFill />
                        </button>
                      </StyledCenteredTableCell>
                    ) : null}
                  </tr>
                )
              })}
              {editingConcurency ? (
                <DynamicRowForm
                  suppliers={concurency}
                  setSuppliers={setConcurency}
                  fieldNames={MarketAnalysisFieldNames.descriptions}
                  isConcurency={true}
                />
              ) : null}
            </StyledTable>
          </section>
          {isPreview ? null : (
            <section className="section">
              {!isEditing && !isNew ? (
                <div className="field is-grouped">
                  <p className="control">
                    <StyledButton
                      className="button is-info"
                      onClick={(event) => {
                        event.preventDefault()
                        setIsEditing(!isEditing)
                      }}
                    >
                      Edytuj
                    </StyledButton>
                    <StyledButton
                      className={`button is-link ${
                        isDownloading ? 'is-loading' : null
                      }`}
                      onClick={downloadReport}
                    >
                      Pobierz Raport
                    </StyledButton>
                  </p>
                </div>
              ) : (
                <div className="field is-grouped">
                  <p className="control">
                    <StyledButton
                      className={`button is-primary ${
                        isSaving ? 'is-loading' : null
                      }`}
                    >
                      Zatwierdź Zmiany
                    </StyledButton>
                  </p>
                  <p className="control">
                    {isEditing && !isNew ? (
                      <StyledButton
                        className="button is-danger"
                        onClick={(event) => {
                          event.preventDefault()
                          handleRevertChanges()
                        }}
                      >
                        Anuluj Zmiany
                      </StyledButton>
                    ) : null}
                  </p>
                </div>
              )}
            </section>
          )}
        </form>
      )}
    </Wrapper>
  )
}
