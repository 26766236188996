export class MarketAnalysisFieldNames {
  static suppliers = [
    'supplier_name_address',
    'product',
    'start_cooperation_date',
    'supplies_share',
    'type_date_payment',
  ]
  static recivers = [
    'reciever_name_address',
    'product',
    'start_cooperation_date',
    'supplies_share',
    'type_date_payment',
  ]
  static descriptions = [
    'competition_name_address',
    'competition_product_service',
    'competition_strong_sides',
    'competition_weak_sides',
  ]
}
