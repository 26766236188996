import axios from 'axios'
import { Constants } from '../utils/Constants'
import { getAuthHeaders } from './AuthService'

export default class CompanyDetailService {
  static getCompanyDetails(company_id, additionalUrl = null, cookies) {
    const finalUrl = `${
      additionalUrl === null
        ? `${Constants.GLOBAL_API_URL}/companies/${company_id}`
        : additionalUrl
    }`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((response) => {
        return response.data
      })
  }

  static saveNewCompany(requestBody, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/companies/`
    return axios.post(finalUrl, requestBody, {
      headers: {
        Authorization: getAuthHeaders(cookies),
      },
    })
  }

  static getCompanyMarketAnalyses(company_id, additionalUrl, cookies) {
    let finalUrl
    if (additionalUrl === null) {
      finalUrl = `${Constants.GLOBAL_API_URL}/applications/market_analysis?company=${company_id}`
    } else {
      finalUrl = additionalUrl
    }
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((response) => {
        return response.data
      })
  }

  static getSingleCompanyMarketAnalysis(analysisId, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/market_analysis/${analysisId}`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static saveCompanyMarketAnalysis(requestBody, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/market_analysis/`
    return axios.post(finalUrl, requestBody, {
      headers: {
        Authorization: getAuthHeaders(cookies),
      },
    })
  }

  static editCompanyMarketAnalysis(requestBody, analysisId, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/market_analysis/${analysisId}/`
    return axios
      .put(finalUrl, requestBody, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static getInternalProcesses(companyId, additionalUrl, cookies) {
    let finalUrl
    if (additionalUrl === null) {
      finalUrl = `${Constants.GLOBAL_API_URL}/applications/internal_processes?company=${companyId}`
    } else {
      finalUrl = additionalUrl
    }
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static getSingleInternalProcess(processId, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/internal_processes/${processId}`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static createInternalProcessesReport(requestBody, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/internal_processes/`
    return axios
      .post(finalUrl, requestBody, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
      .catch((error) => alert(error))
  }

  static editInternalProcessesReport(requestBody, reportId, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/internal_processes/${reportId}/`
    return axios
      .put(finalUrl, requestBody, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
      .catch((error) => alert(error))
  }

  static getAvailableMarketAnalysisComparison(
    companyId,
    isExcluded = true,
    cookies
  ) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/getOptions/marketAnalysis/?comp=${companyId}&ex=${isExcluded}`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static getAvailableInternalProcessesComparison(
    companyId,
    isExcluded = true,
    cookies
  ) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/getOptions/internalProcesses/?comp=${companyId}&ex=${isExcluded}`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((result) => {
        return result.data
      })
  }

  static downloadMarketAnalysis(reportId, cookies) {
    return axios.get(
      `${Constants.GLOBAL_API_URL}/generate/marketAnalysis/${reportId}/`,
      {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
        responseType: 'blob',
      }
    )
  }

  static downloadInternalProcesses(reportId, cookies) {
    return axios.get(
      `${Constants.GLOBAL_API_URL}/generate/internallProcesses/${reportId}/`,
      {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
        responseType: 'blob',
      }
    )
  }
}
