import React, { useEffect, useState } from 'react'

import { InternalProcessTable } from './InternalProcessTable'
import CompanyDetailService from '../../services/CompanyDetailService'
import { useNavigate, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import { Wrapper, SectionBar, CenterBox } from './InternalProcesses.styles'
import { TailSpin } from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { authorize } from '../../services/AuthService'
import { saveAs } from 'file-saver'

export const InternalProcesses = ({
  isNew = false,
  isPreview = false,
  forcedId = null,
  forcedTitle = null,
}) => {
  const [isEdited, setIsEdited] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const { internalProcessId } = useParams()
  const { companyId } = useParams()
  const [cookies] = useCookies()
  const navigate = useNavigate()

  const [initialData, setInitialData] = useState({})
  const [a1, setA1] = useState([])
  const [a2, setA2] = useState([])
  const [a3, setA3] = useState([])
  const [a4, setA4] = useState([])
  const [a5, setA5] = useState([])
  const [a6, setA6] = useState([])
  const [a7, setA7] = useState([])
  const [a8, setA8] = useState([])
  const [a9, setA9] = useState([])
  const [a10, setA10] = useState([])

  const [spinner, setSpinner] = useState(false)
  const prepareBody = () => {
    setIsSaving(true)
    const requestBody = {}
    requestBody['a1'] = a1
    requestBody['a2'] = a2
    requestBody['a3'] = a3
    requestBody['a4'] = a4
    requestBody['a5'] = a5
    requestBody['a6'] = a6
    requestBody['a7'] = a7
    requestBody['a8'] = a8
    requestBody['a9'] = a9
    requestBody['a10'] = a10
    if (isNew) {
      requestBody['company'] = companyId
      CompanyDetailService.createInternalProcessesReport(requestBody, cookies)
        .then((result) => {
          setIsSaving(false)
          navigate(`/internal_processes/${result.id}`)
          setIsEdited(false)
        })
        .catch((error) => alert(error))
    } else {
      requestBody['company'] = initialData.company
      requestBody['id'] = initialData.id
      CompanyDetailService.editInternalProcessesReport(
        requestBody,
        requestBody['id'],
        cookies
      ).then(() => {
        setIsSaving(false)
        setIsEdited(false)
      })
    }
  }

  const revertChanges = () => {
    setA1(initialData.a1)
    setA2(initialData.a2)
    setA3(initialData.a3)
    setA4(initialData.a4)
    setA5(initialData.a5)
    setA6(initialData.a6)
    setA7(initialData.a7)
    setA8(initialData.a8)
    setA9(initialData.a9)
    setA10(initialData.a10)
  }

  const downloadReport = () => {
    setIsDownloading(true)
    CompanyDetailService.downloadInternalProcesses(internalProcessId, cookies)
      .then((result) => {
        setIsDownloading(false)
        saveAs(result.data, 'excel-report.xlsx')
      })
      .catch(() => {
        setIsDownloading(false)
        alert('Błąd pobierania pliku')
      })
  }

  useEffect(() => {
    setSpinner(true)
    if (isNew) {
      authorize(cookies)
        .then(() => {
          setSpinner(false)
          setIsEdited(true)
        })
        .catch(() => navigate('/login'))
    } else {
      let idToDownload
      if (forcedId !== null) {
        idToDownload = forcedId
      } else if (internalProcessId !== undefined) {
        idToDownload = internalProcessId
      } else {
        navigate('/home')
      }
      CompanyDetailService.getSingleInternalProcess(idToDownload, cookies).then(
        (result) => {
          setA1(result.a1)
          setA2(result.a2)
          setA3(result.a3)
          setA4(result.a4)
          setA5(result.a5)
          setA6(result.a6)
          setA7(result.a7)
          setA8(result.a8)
          setA9(result.a9)
          setA10(result.a10)
          setInitialData(cloneDeep(result))
          setSpinner(false)
        }
      )
    }
  }, [])
  return (
    <Wrapper>
      <SectionBar>
        {forcedTitle ? forcedTitle : 'Analiza procesów wewntęrznych'}
      </SectionBar>
      {spinner ? (
        <CenterBox>
          <TailSpin />
        </CenterBox>
      ) : (
        <div className="columns">
          <div className="column">
            <section className="section">
              <h1 className="title">Czas Wprowadzania Nowości na Rynek</h1>
              <InternalProcessTable
                data={a1}
                setData={setA1}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">Czas Zarządzania Realizacją Zamówień</h1>
              <InternalProcessTable
                data={a3}
                setData={setA3}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">Czas Zarządzania Stanami Magazynowymi</h1>
              <InternalProcessTable
                data={a5}
                setData={setA5}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">
                Czas Udostępniania Oferty Handlowej Potencjalnym Kontrahentom
              </h1>
              <InternalProcessTable
                data={a7}
                setData={setA7}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">
                Czas Zarządzania oraz Realizacji Programu Lojalnościowego
              </h1>
              <InternalProcessTable
                data={a9}
                setData={setA9}
                isEdited={isEdited}
              />
            </section>
            {isPreview ? null : (
              <section className="section">
                {isEdited ? (
                  <div className="field is-grouped">
                    <p className="control">
                      <button
                        className={`button is-success ${
                          isSaving ? 'is-loading' : null
                        }`}
                        onClick={prepareBody}
                      >
                        Zatwierdź Zmiany
                      </button>
                    </p>
                    <p className="control">
                      {isEdited & !isNew ? (
                        <button
                          className="button is-danger"
                          onClick={() => {
                            setIsEdited(false)
                            revertChanges()
                          }}
                        >
                          Odrzuć Zmiany
                        </button>
                      ) : null}
                    </p>
                  </div>
                ) : (
                  <div className="field is-grouped">
                    <p className="control">
                      <button
                        className={`button is-link ${
                          isDownloading ? 'is-loading' : null
                        }`}
                        onClick={downloadReport}
                      >
                        Pobierz Raport
                      </button>{' '}
                    </p>
                    <p className="control">
                      <button
                        className="button is-info"
                        onClick={() => {
                          setIsEdited(true)
                        }}
                      >
                        Edytuj
                      </button>
                    </p>
                  </div>
                )}
              </section>
            )}
          </div>
          <div className="column is-6">
            <section className="section">
              <h1 className="title">Czas Składania Zamówień</h1>
              <InternalProcessTable
                data={a2}
                setData={setA2}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">Czas Obsługi Promocji i Wyprzedaży</h1>
              <InternalProcessTable
                data={a4}
                setData={setA4}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">Czas Obsługi i Reklamacji</h1>
              <InternalProcessTable
                data={a6}
                setData={setA6}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">Czas Realizacji Dostaw</h1>
              <InternalProcessTable
                data={a8}
                setData={setA8}
                isEdited={isEdited}
              />
            </section>
            <section className="section">
              <h1 className="title">
                Czas Opisywania i Aktualizacji Bazy Produktowej
              </h1>
              <InternalProcessTable
                data={a10}
                setData={setA10}
                isEdited={isEdited}
              />
            </section>
          </div>
        </div>
      )}
    </Wrapper>
  )
}
