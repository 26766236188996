import React, { useEffect } from 'react'
import { Wrapper, SectionBar, Form } from './AddCompany.styles'
import { useForm } from 'react-hook-form'
import CompanyDetailService from 'services/CompanyDetailService'
import { authorize } from '../../services/AuthService'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
// import axios from 'axios'

const AddCompanyError = () => {
  return <p className="has-text-danger">To pole jest wymagane</p>
}
export const AddCompany = () => {
  const [cookies] = useCookies()
  const navigate = useNavigate()

  useEffect(() => {
    authorize(cookies).catch(() => navigate('/login'))
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm()

  const submitData = (data) => {
    CompanyDetailService.saveNewCompany(data, cookies)
      .then(() => {
        setValue('company_name', null)
        setValue('company_regon', null)
        setValue('company_nip', null)
        setValue('company_address', null)
        setValue('company_contact', null)
      })
      .catch(() => alert('Błąd Zapisu'))
  }
  return (
    <Wrapper>
      <SectionBar>Dodaj firmę</SectionBar>
      <Form onSubmit={handleSubmit(submitData)}>
        <label htmlFor="companyName">
          Nazwa Firmy:
          <input
            {...register('company_name', {
              required: true,
            })}
            type="text"
            id="companyName"
            className="input"
            required
          />
          {errors.company_name && <AddCompanyError />}
        </label>
        <label htmlFor="companyRegon">
          REGON:
          <input
            {...register('company_regon', { required: true, maxLength: 9 })}
            type="number"
            id="companyRegon"
            className="input"
            required
          />
          {errors.company_regon && <AddCompanyError />}
        </label>
        <label htmlFor="companyNIP">
          NIP:
          <input
            {...register('company_nip', { required: true, maxLength: 10 })}
            type="number"
            id="companyNIP"
            className="input"
            required
          />
          {errors.company_nip && <AddCompanyError />}
        </label>
        <label htmlFor="companyAddress">
          Adres:
          <input
            {...register('company_address', { required: true })}
            type="text"
            id="companyRegon"
            className="input"
            required
          />
          {errors.company_address && <AddCompanyError />}
        </label>
        <label htmlFor="companyRegon">
          Adres kontaktowy firmy:
          <input
            {...register('company_contact', { required: true })}
            type="text"
            id="companyConact"
            className="input"
            required
          />
          {errors.company_contact && <AddCompanyError />}
        </label>
        <button type="submit" className="button is-primary">
          Dodaj firme
        </button>
      </Form>
    </Wrapper>
  )
}
