import { Constants } from '../utils/Constants'
import axios from 'axios'
import { getAuthHeaders } from './AuthService'

export default class HomePageService {
  static getCompanies(additionalUrl, cookies) {
    let finalUrl
    const authData = getAuthHeaders(cookies)
    if (additionalUrl === null) {
      finalUrl = `${Constants.GLOBAL_API_URL}/companies?is_short=True&`
    } else {
      finalUrl = additionalUrl
    }
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: authData,
        },
      })
      .then((response) => {
        return response.data
      })
  }

  static getFiledApplications(
    additionalUrl,
    forManager = false,
    is_liquidity = true,
    is_all = false,
    cookies
  ) {
    let finalUrl
    const authData = getAuthHeaders(cookies)
    if (additionalUrl === null) {
      finalUrl = `${
        Constants.GLOBAL_API_URL
      }/applications?is_short=True&m=${forManager.toString()}&li=${is_liquidity.toString()}&all=${is_all.toString()}`
    } else {
      finalUrl = additionalUrl
    }
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: authData,
        },
      })
      .then((result) => {
        return result.data
      })
  }
}
