import React from 'react'
import {
  Wrapper,
  StyledTable,
  StyledTableCell,
} from './SubjectiveFactorsTable.styles'
import {
  StyledSpan,
  StyledTableSum,
} from '../ObjectiveFactorsTable/ObjectiveFactorsTable.styles'

const SubjectiveFormError = () => {
  return (
    <p className="has-text-danger">
      Pole nie może być puste, a jego wartość musi należeć do podanego
      przedziału
    </p>
  )
}

export const SubjectiveFactorsTable = ({
  subjectiveFactors,
  setSubjectiveFacotrs,
  isDisabled,
  registerForm,
  formErrors,
  clearErrors,
}) => {
  return (
    <Wrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>Wyszczególnienie</th>
            <th>Przedział Punktowy</th>
            <th>Ilość Przyznanych Punktów</th>
            <th>Waga</th>
            <th>Wynik Oceny</th>
            <th>Uzasadnienie Oceny</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell colSpan="3">
            I. Pozycja przedsiębiorcy na rynku (1+2+3+4)
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.entrepreneur_position_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.entrepreneur_position_score}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>1. Możliwości zbytu</StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.marketability_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.marketability_auto_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('marketability', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={subjectiveFactors.marketability_auto_points}
                  id="marketability_auto_points"
                  onChange={(event) => {
                    clearErrors('marketability')
                    subjectiveFactors.marketability_auto_points =
                      event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.marketability && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.marketability_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.marketability_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.marketability_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.marketability_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="marketability_justification"
                onChange={(event) => {
                  subjectiveFactors.marketability_justification =
                    event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
                disabled={isDisabled}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>2. Produkt (usługa) / konkurencja</StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.product_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.product_auto_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('product', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  className="input"
                  defaultValue={subjectiveFactors.product_auto_points}
                  id="product_auto_points"
                  onChange={(event) => {
                    clearErrors('product')
                    subjectiveFactors.product_auto_points = event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.product && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>{subjectiveFactors.product_factor}</StyledTableCell>
          <StyledTableCell>{subjectiveFactors.product_score}</StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.product_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.product_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="product_justification"
                onChange={(event) => {
                  subjectiveFactors.product_justification = event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
                disabled={isDisabled}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            3. Uzależnienie od dostawców / odbiorców oraz powiązania kapitałowe
            i organizacyjne{' '}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.equity_links_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.equity_links_auto_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('equity', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={subjectiveFactors.equity_links_auto_points}
                  id="equity_links_auto_points"
                  onChange={(event) => {
                    clearErrors('equity')
                    subjectiveFactors.equity_links_auto_points =
                      event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.equity && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.equity_links_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.equity_links_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.equity_links_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.equity_links_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="equity_links_justification"
                onChange={(event) => {
                  subjectiveFactors.equity_links_justification =
                    event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
                disabled={isDisabled}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            4. Poziom nowoczesności wyposażenia technicznego i technologii
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.technological_level_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.technological_level_auto_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('technology', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={
                    subjectiveFactors.technological_level_auto_points
                  }
                  id="technological_level_auto_points"
                  onChange={(event) => {
                    clearErrors('technology')
                    subjectiveFactors.technological_level_auto_points =
                      event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.technology && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.technological_level_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.technological_level_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.technological_level_justification
            ) : (
              <textarea
                defaultValue={
                  subjectiveFactors.technological_level_justification
                }
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="technological_level_justification"
                onChange={(event) => {
                  subjectiveFactors.technological_level_justification =
                    event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell colSpan="3">
            II. Zarządzanie i wiarygodność przedsiębiorcy (1+2+3){' '}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.credibility_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.credibility_score}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            1. Styl zarządzania i kwalifikacje kierownictwa
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.management_style_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.management_style_auto_points
            ) : (
              <div className="control">
                <input
                  step="0.01"
                  {...registerForm('management', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  type="number"
                  className="input"
                  defaultValue={subjectiveFactors.management_style_auto_points}
                  id="management_style_auto_points"
                  onChange={(event) => {
                    clearErrors('management')
                    subjectiveFactors.management_style_auto_points =
                      event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.management && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.management_style_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.management_style_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.management_style_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.management_style_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="management_style_justification"
                onChange={(event) => {
                  subjectiveFactors.management_style_justification =
                    event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            2. Historia funkcjonowania przedsiębiorcy{' '}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.history_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.history_auto_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('history', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={subjectiveFactors.history_auto_points}
                  id="history_auto_points"
                  onChange={(event) => {
                    clearErrors('history')
                    subjectiveFactors.history_auto_points = event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                  disabled={isDisabled}
                />
                {formErrors.history && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>{subjectiveFactors.history_factor}</StyledTableCell>
          <StyledTableCell>{subjectiveFactors.history_score}</StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.history_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.history_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="history_justification"
                onChange={(event) => {
                  subjectiveFactors.history_justification = event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            3. Stosunki z instytucjami finansowymi{' '}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.financial_ins_rel_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.financial_ins_rel_auto_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('financial', {
                    required: true,
                    min: 0,
                    max: 2,
                  })}
                  className="input"
                  defaultValue={subjectiveFactors.financial_ins_rel_auto_points}
                  id="financial_ins_rel_auto_points"
                  onChange={(event) => {
                    clearErrors('financial')
                    subjectiveFactors.financial_ins_rel_auto_points =
                      event.target.value
                    setSubjectiveFacotrs(subjectiveFactors)
                  }}
                />
                {formErrors.financial && <SubjectiveFormError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.financial_ins_rel_factor}
          </StyledTableCell>
          <StyledTableCell>
            {subjectiveFactors.financial_ins_rel_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              subjectiveFactors.financial_ins_rel_justification
            ) : (
              <textarea
                defaultValue={subjectiveFactors.financial_ins_rel_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="financial_ins_rel_justification"
                onChange={(event) => {
                  subjectiveFactors.financial_ins_rel_justification =
                    event.target.value
                  setSubjectiveFacotrs(subjectiveFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableSum className="title is-4" colSpan="9">
            Suma punktów:{' '}
            <StyledSpan>{subjectiveFactors.points_sum}</StyledSpan>
          </StyledTableSum>
        </tr>
      </StyledTable>
    </Wrapper>
  )
}
