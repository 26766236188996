import axios from 'axios'
import { Constants } from '../utils/Constants'
import { getAuthHeaders } from './AuthService'

export default class SyntheticEvaluationService {
  static getReport(applicationId, isManager, cookies) {
    const authHeader = getAuthHeaders(cookies)
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/syntheticRaport/${applicationId}/?m=${isManager}`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        return response.data
      })
  }

  static editReport(reportBody, applicationId, isManager = false, cookies) {
    console.log('gonna get headers')
    const authHeader = getAuthHeaders(cookies)
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/syntheticRaport/${applicationId}/?m=${isManager}`
    console.log(finalUrl)
    return axios.put(finalUrl, reportBody, {
      headers: {
        Authorization: authHeader,
      },
    })
  }

  static downloadReport(reportID, cookies) {
    return axios.get(
      `${Constants.GLOBAL_API_URL}/generate/syntheticEvaluation/${reportID}/`,
      {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
        responseType: 'blob',
      }
    )
  }
}
