import axios from 'axios'
import { dataMapper } from '../utils/DataMapper'
import { Constants } from '../utils/Constants'
import { cloneDeep } from 'lodash'
import { getAuthHeaders } from './AuthService'

export default class ApplicationViewService {
  static fetchApplication(applicationId, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/${applicationId}/`
    return axios
      .get(finalUrl, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((response) => {
        let initialData = cloneDeep(response.data)
        let newData = dataMapper(response.data.data)
        newData.push(initialData)
        return newData
      })
  }

  static updateApplication(applicationId, requestBody, cookies) {
    const finalUrl = `${Constants.GLOBAL_API_URL}/applications/${applicationId}/`
    return axios
      .put(finalUrl, requestBody, {
        headers: {
          Authorization: getAuthHeaders(cookies),
        },
      })
      .then((response) => {
        return response
      })
  }
}
