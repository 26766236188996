import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompareForm } from './CompareForm'
import { NewMarketAnalysis } from '../MarketAnalysis/MarketAnalysis'
import CompanyDetailService from '../../services/CompanyDetailService'
import { InternalProcesses } from '../InternalProcesses /InternalProcesses'
import {
  ComWrapper,
  ComSlider,
  ComSliderElement,
  StyledLink,
  ComSliderElementIcon,
} from './CompanyComparision.styles'

import { BsArrowBarLeft } from 'react-icons/bs'
import { useCookies } from 'react-cookie'

export const CompanyComparison = ({ isMarketAnalysis = true }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [hasSelectEnded, setHasSelectEnded] = useState(false)
  const [mainAnalysis, setMainAnalysis] = useState([])

  const { mainCompanyId } = useParams()
  const [cookies] = useCookies([])

  const [showComparision, setShowComparision] = useState([])

  useEffect(() => {
    console.log(cookies)
    {
      isMarketAnalysis
        ? CompanyDetailService.getAvailableMarketAnalysisComparison(
            mainCompanyId,
            false,
            cookies
          )
            .then((result) => {
              setMainAnalysis(result[0])
            })
            .catch((error) => console.log(error))
        : CompanyDetailService.getAvailableInternalProcessesComparison(
            mainCompanyId,
            false,
            cookies
          )
            .then((result) => {
              setMainAnalysis(result[0])
            })
            .catch((error) => console.log(error))
    }
  }, [])

  const confirmSelection = () => {
    selectedCompanies.forEach((company) => {
      showComparision[selectedCompanies.indexOf(company)] = false
      setShowComparision(showComparision)
    })
    setHasSelectEnded(true)
  }

  return (
    <ComWrapper>
      {hasSelectEnded ? (
        <>
          <StyledLink
            to={`/companies/${mainCompanyId}`}
            className="button is-light"
          >
            Powrót do Szczegółów Firmy
          </StyledLink>
          <ComSlider>
            <ComSliderElement open={true}>
              {isMarketAnalysis ? (
                <NewMarketAnalysis
                  isNew={false}
                  forcedAnalysisId={mainAnalysis.market_analysis_id}
                  isPreview={true}
                  forcedTitle={mainAnalysis.company_name}
                />
              ) : (
                <InternalProcesses
                  isNew={false}
                  forcedId={mainAnalysis.internal_processes_id}
                  isPreview={true}
                  forcedTitle={mainAnalysis.company_name}
                />
              )}
              )
            </ComSliderElement>
            {selectedCompanies.map((company) => {
              selectedCompanies.indexOf(company)
              return (
                <ComSliderElement
                  open={showComparision[selectedCompanies.indexOf(company)]}
                  key={company.company_id}
                >
                  <ComSliderElementIcon
                    onClick={() => {
                      showComparision[selectedCompanies.indexOf(company)] =
                        !showComparision[selectedCompanies.indexOf(company)]
                      setShowComparision([...showComparision])
                    }}
                  >
                    <BsArrowBarLeft />
                  </ComSliderElementIcon>
                  {isMarketAnalysis ? (
                    <NewMarketAnalysis
                      isNew={false}
                      forcedAnalysisId={company.market_analysis_id}
                      isPreview={true}
                      forcedTitle={company.company_name}
                    />
                  ) : (
                    <InternalProcesses
                      isNew={false}
                      forcedId={company.internal_processes_id}
                      isPreview={true}
                      forcedTitle={company.company_name}
                    />
                  )}
                </ComSliderElement>
              )
            })}
          </ComSlider>
        </>
      ) : (
        <CompareForm
          setSelectedCompanies={setSelectedCompanies}
          selectedCompanies={selectedCompanies}
          mainCompanyId={mainCompanyId}
          confirmSelection={confirmSelection}
          downloadDataFunction={
            isMarketAnalysis
              ? CompanyDetailService.getAvailableMarketAnalysisComparison
              : CompanyDetailService.getAvailableInternalProcessesComparison
          }
        />
      )}
    </ComWrapper>
  )
}
