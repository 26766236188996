import React, { useState } from 'react'
import {
  StyledCenteredTableCell,
  StyledTableCell,
} from '../../views/ApplicationsView/ApplicationsView.styles'

export const DynamicRowForm = ({
  suppliers,
  setSuppliers,
  fieldNames,
  isConcurency = false,
}) => {
  const [newItem, setNewItem] = useState({})

  const submit = () => {
    suppliers.push(newItem)
    setSuppliers([...suppliers])
    setNewItem({})
  }

  return (
    <tr>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Nazwa Dostawcy i Adres"
                onChange={(event) => {
                  newItem[fieldNames[0]] = event.target.value
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Nazwa Dostawcy i Adres"
                onChange={(event) => {
                  newItem[fieldNames[1]] = event.target.value
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type={isConcurency ? 'text' : 'date'}
                placeholder="Nazwa Dostawcy i Adres"
                onChange={(event) => {
                  newItem[fieldNames[2]] = event.target.value
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type={isConcurency ? 'text' : 'number'}
                placeholder="Nazwa Dostawcy i Adres"
                onChange={(event) => {
                  newItem[fieldNames[3]] = event.target.value
                  setNewItem(newItem)
                }}
              />
            </p>
          </div>
        </div>
      </StyledTableCell>
      {!isConcurency ? (
        <StyledTableCell>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="Nazwa Dostawcy i Adres"
                  onChange={(event) => {
                    newItem[fieldNames[4]] = event.target.value
                    setNewItem(newItem)
                  }}
                />
              </p>
            </div>
          </div>
        </StyledTableCell>
      ) : null}
      <StyledCenteredTableCell>
        <button
          className="button is-success"
          onClick={(event) => {
            event.preventDefault()
            submit()
          }}
        >
          Dodaj
        </button>
      </StyledCenteredTableCell>
    </tr>
  )
}
