import React, { useState } from 'react'
import {
  StyledCenteredTableCell,
  StyledTable,
  StyledTableCell,
} from '../ApplicationsView/ApplicationsView.styles'
import { GiCancel } from 'react-icons/gi'
import { GrAdd } from 'react-icons/gr'
import { InterProcTableRow } from './InterProcTableRow'
import { BsFillTrashFill } from 'react-icons/bs'

export const InternalProcessTable = ({ data, setData, isEdited }) => {
  const [isDataEdited, ssetIsDataEdited] = useState(false)
  const timeValues = { h: 'Godzina', d: 'Dzień', w: 'Tydzień', min: 'Minuta' }

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Czynność</th>
          <th>Jednostka Czasu</th>
          <th>Czas</th>
          {isEdited ? (
            <StyledCenteredTableCell className="container">
              <button
                className="button is-text"
                onClick={(event) => {
                  event.preventDefault()
                  ssetIsDataEdited(!isDataEdited)
                }}
              >
                {isDataEdited ? <GiCancel /> : <GrAdd />}
              </button>
            </StyledCenteredTableCell>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {data != null
          ? data.map((singleData) => (
              <tr key={data.indexOf(singleData)}>
                <StyledTableCell>{singleData.activity}</StyledTableCell>
                <StyledTableCell>{timeValues[singleData.unit]}</StyledTableCell>
                <StyledTableCell>{singleData.duration}</StyledTableCell>
                {isEdited ? (
                  <StyledCenteredTableCell className="container">
                    <button
                      className="button is-text"
                      onClick={(event) => {
                        event.preventDefault()
                        data.splice(data.indexOf(singleData), 1)
                        setData([...data])
                      }}
                    >
                      <BsFillTrashFill />
                    </button>
                  </StyledCenteredTableCell>
                ) : null}
              </tr>
            ))
          : null}
        {isDataEdited && isEdited ? (
          <InterProcTableRow coreData={data} setCoreData={setData} />
        ) : null}
      </tbody>
    </StyledTable>
  )
}
