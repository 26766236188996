import React from 'react'
import {
  StyledTable,
  StyledTableCell,
} from '../SubjectiveFactorsTable/SubjectiveFactorsTable.styles'

// eslint-disable-next-line no-unused-vars
export const SummaryTable = ({ summary, setSummary, isDisabled }) => {
  const ratingLevels = {
    'AAA-A': 'Wysoki',
    BBB: 'Dobry',
    BB: 'Zadowalający',
    B: 'Niski',
    CCC: 'Zły',
  }
  const safeguardsLevels = { 1: 'Wysoki', 2: 'Standardowy', 3: 'Niski' }
  return (
    <div>
      <StyledTable>
        <thead>
          <tr>
            <th>Rating Wnioskodawcy</th>
            <th>Łączna Punktacja</th>
            <th>Łączny Poziom Zabezpieczeń</th>
            <th>Ocena Poziomu Zabezpieczeń</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell>
            {ratingLevels[summary.applicant_rating]}
          </StyledTableCell>
          <StyledTableCell>{summary.points_sum}</StyledTableCell>
          <StyledTableCell>
            {(parseFloat(summary.safeguards_score) * 100).toFixed(2) + '%'}
          </StyledTableCell>
          <StyledTableCell>
            {safeguardsLevels[parseInt(summary.safeguards_level)]}
          </StyledTableCell>
        </tr>
      </StyledTable>
    </div>
  )
}
