import React from 'react'
import { NavWrapper } from './Navigation.styles'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { useCookies } from 'react-cookie'
import { performLogout } from '../../services/AuthService'
import { Link, useNavigate } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
export const Navigation = ({ isLoggedIn, setIsLoggedIn }) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies()
  const navigate = useNavigate()

  const logout = () => {
    performLogout(cookies)
      .then(() => {
        removeCookie('token', { path: '/' })
        removeCookie('userID', { path: '/' })
        setIsLoggedIn(false)
        navigate('/login/')
      })
      .catch(() => alert('Wylogowanie nie powiodło się'))
  }

  return (
    <NavWrapper className="navbar">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Logo width="60px" />
        </div>
      </div>
      {cookies['token'] ? (
        <>
          <div className="navbar-start">
            <Link className="navbar-item" to="/home">
              Strona Domowa
            </Link>
            <Link className="navbar-item" to="/dodaj-firme">
              Dodaj firmę
            </Link>
            <Link className="navbar-item" to="/archive/">
              Archiwum Wniosków
            </Link>
            <Link className="navbar-item" to="/settings/">
              Ustawienia
            </Link>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <button className="button is-light" onClick={logout}>
                Wyloguj Się
              </button>
            </div>
          </div>
        </>
      ) : null}
    </NavWrapper>
  )
}
