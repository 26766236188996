import React, { useState } from 'react'
import {
  Wrapper,
  StyledTable,
  StyledTableCell,
  StyledTableSum,
  StyledSpan,
} from './LiquidityFactors.styles'

const LiquidityError = () => {
  return (
    <p className="has-text-danger">
      Wartość pola musi należeć do przedziału od 0 do 2
    </p>
  )
}

const formRegisterOptions = { required: true, min: 0, max: 2 }

export const LiquidityFactorsTable = ({
  liquidityFactors,
  setLiquidityFactors,
  isDisabled,
  registerForm,
  formErrors,
  clearError,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [setValidation, Validation] = useState(false)
  return (
    <Wrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>Wyszczególnienie</th>
            <th>Wskaźnik</th>
            <th>Przedział Punktowy</th>
            <th>Ilość przyznanych punktów</th>
            <th>Waga</th>
            <th>Wynik Oceny</th>
            <th>Uzasadnienie Oceny</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell colSpan="3">
            Jakość przedsięwzięcia inwestycyjnego (1+2+3)
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.project_quality_factor}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            1. Analiza bieżących potrzeb związanych z utrzymaniem bieżącej
            płynności finansowej
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.current_needs_indicator}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.current_needs_score_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.current_needs_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('currentNeeds', formRegisterOptions)}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={liquidityFactors.current_needs_points}
                  id="current_needs_points"
                  onChange={(event) => {
                    clearError('currentNeeds')
                    liquidityFactors.current_needs_points = event.target.value
                    setLiquidityFactors(liquidityFactors)
                  }}
                />
                {formErrors.currentNeeds && <LiquidityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.current_needs_factor}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.current_needs_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.current_needs_justification
            ) : (
              <textarea
                defaultValue={liquidityFactors.current_needs_justification}
                placeholder="Uzasadnienie oceny"
                id="current_needs_justification"
                className="textarea has-fixed-size"
                onChange={(event) => {
                  liquidityFactors.current_needs_justification =
                    event.target.value
                  setLiquidityFactors(liquidityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            2. Analiza zapotrzebowania na kapitał obrotowy / finansowanie
            bieżących potrzeb związanych z prowadzoną działalnością gospodarczą
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.capital_needs_indicator}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.capital_needs_score_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.capital_needs_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('capitalNeeds', formRegisterOptions)}
                  className="input"
                  defaultValue={liquidityFactors.capital_needs_points}
                  id="capital_needs_points"
                  onChange={(event) => {
                    clearError('capitalNeeds')
                    liquidityFactors.capital_needs_points = event.target.value
                    setLiquidityFactors(liquidityFactors)
                  }}
                />
                {formErrors.capitalNeeds && <LiquidityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.capital_needs_factor}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.capital_needs_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.capital_needs_justification
            ) : (
              <textarea
                defaultValue={liquidityFactors.capital_needs_justification}
                placeholder="Uzasadnienie oceny"
                id="capital_needs_justification"
                className="textarea has-fixed-size"
                rows="7"
                onChange={(event) => {
                  liquidityFactors.capital_needs_justification =
                    event.target.value
                  setLiquidityFactors(liquidityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            3. Analiza osiągnięcia przez Wnioskodawcę poprawy w zakresie
            płynności firmy i utrzymania prowadzonej działalności oraz realność
            przyjętych założeń z BIZNES PLANU
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.achievements_score_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.achievements_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('achievements', formRegisterOptions)}
                  type="number"
                  step="0.01"
                  defaultValue={liquidityFactors.achievements_points}
                  className="input"
                  id="achievements_points"
                  onChange={(event) => {
                    clearError('achievements')
                    liquidityFactors.achievements_points = event.target.value
                    setLiquidityFactors(liquidityFactors)
                  }}
                />
                {formErrors.achievements && <LiquidityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.achievements_factor}
          </StyledTableCell>
          <StyledTableCell>
            {liquidityFactors.achievements_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              liquidityFactors.achievements_justification
            ) : (
              <textarea
                defaultValue={liquidityFactors.achievements_justification}
                className="textarea has-fixed-size"
                placeholder="Uzasadnienie oceny"
                id="achievements_justification"
                rows="10"
                onChange={(event) => {
                  liquidityFactors.achievements_justification =
                    event.target.value
                  setLiquidityFactors(liquidityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableSum className="title is-4" colSpan="7">
            Suma punktów: <StyledSpan>{liquidityFactors.score_sum}</StyledSpan>
          </StyledTableSum>
        </tr>
      </StyledTable>
    </Wrapper>
  )
}
