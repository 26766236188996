import React from 'react'
import {
  StyledCenteredTableCell,
  StyledTableCell,
} from './SafeguardsTable.styles'

const SafeguardsTextError = () => {
  return <p className="has-text-danger">Pole jest wymagane</p>
}

const SafeguardsNumberError = () => {
  return (
    <p className="has-text-danger">
      Pole jest wymagane, a jego wartość powinna być większa niż 0
    </p>
  )
}

export const SafeguardsDynamicRow = ({
  register,
  errors,
  clearErrors,
  editedObject,
  setEditedObject,
}) => {
  return (
    <tr>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                {...register('description', {
                  required: true,
                })}
                defaultValue={editedObject['description']}
                className="input"
                type="text"
                placeholder="Opis"
                onChange={(event) => {
                  editedObject['description'] = event.target.value
                  setEditedObject(editedObject)
                  clearErrors('description')
                }}
              />
            </p>
          </div>
          {errors.description && <SafeguardsTextError />}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                {...register('value', {
                  required: true,
                  min: 0,
                })}
                defaultValue={editedObject['safeguard_value']}
                className="input"
                type="number"
                step="0.1"
                placeholder="Wartość Zabezpieczenia"
                onChange={(event) => {
                  editedObject['safeguard_value'] = parseFloat(
                    event.target.value
                  )
                  setEditedObject(editedObject)
                  clearErrors('value')
                }}
              />
            </p>
          </div>
          {errors.value && <SafeguardsNumberError />}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                {...register('loan', {
                  required: true,
                  min: 0,
                })}
                defaultValue={editedObject['loan_value']}
                className="input"
                type="number"
                step="0.1"
                placeholder="Wartość Pożyczki i Odsetek"
                onChange={(event) => {
                  editedObject['loan_value'] = parseFloat(event.target.value)
                  setEditedObject(editedObject)
                  clearErrors('loan')
                }}
              />
            </p>
          </div>
          {errors.loan && <SafeguardsNumberError />}
        </div>
      </StyledTableCell>
      <StyledTableCell>{editedObject.result}</StyledTableCell>
      <StyledCenteredTableCell colspan="2">
        <button className="button is-primary">Dodaj</button>
      </StyledCenteredTableCell>
    </tr>
  )
}
