/**
 * Class that represents most of the application data
 */

export class ThreeObjectData {
  constructor(key, data) {
    this.name = key
    this.value = data.wartosc
    this.comment = data.komentarz
    this.ready = data.gotowe
  }
}

/**
 * These classes are important to the financial analysis and should be separate object
 */

export class Assets {
  constructor(timePeriodKey, data) {
    this.timePeriod = timePeriodKey
    this.AktywaObrotowe = data.aktywaObrotowe
    this.AktywaRazem = data.aktywaRazem
    this.AktywaTrwale = data.aktywaTrwale
    this.Budynki = data.budynki
    this.Grunty = data.grunty
    this.NaleznosciKrotkoterminowe = data.naleznosciKrotkoterminowe
    this.PozostaleAktywaObrotowe = data.pozostaleAktywaObrotowe
    this.PozostaleAktywaTrwale = data.pozostaleAktywaTrwale
    this.PozostaleSrodkiTrwale = data.pozostaleSrodkiTrwale
    this.RzeczoweAktywa = data.rzeczoweAktywa
    this.SrodkiPieniezne = data.srodkiPieniezne
    this.SrodkiTransportu = data.srodkiTransportu
    this.UrzadzeniaTechniczne = data.urzadzeniaTechniczne
    this.WartosciNiematerialne = data.wartosciNiematerialne
    this.Zapasy = data.zapasy
    this.timePeriodValue = data.wartosc
  }
}

export class Debts {
  constructor(timePeriodKey, data) {
    this.TimePeriodKey = timePeriodKey
    this.TimePeriodValue = data.wartosc
    this.Kapital = data.kapital
    this.PasywaRazem = data.pasywaRazem
    this.RezerwyNaZobowiazania = data.rezerwyNaZobowiazania
    this.RozliczeniaMiedzykasowe = data.rozliczeniaMiedzykasowe
    this.ZobowiazaniaDlugoterminowe = data.zobowiazaniaDlugoterminowe
    this.ZobowiazaniaDlugoterminoweKredytyIPozyczki =
      data.zobowiazaniaDlugoterminoweKredytyIPozyczki
    this.ZobowiazaniaDlugoterminowePozostale =
      data.zobowiazaniaDlugoterminowePozostale
    this.ZobowiazaniaIRezerwy = data.zobowiazaniaIRezerwy
    this.ZobowiazaniaKrotkoterminowe = data.zobowiazaniaKrotkoterminowe
    this.ZobowiazaniaKrotkoterminoweKredytyIPozyczki =
      data.zobowiazaniaKrotkoterminoweKredytyIPozyczki
    this.ZobowiazaniaKrotkoterminowePozostale =
      data.zobowiazaniaKrotkoterminowePozostale
    this.ZobowiazaniaKrotkoterminoweZTytuluDostaw =
      data.zobowiazaniaKrotkoterminoweZTytuluDostaw
  }
}

class IncomeLosses {
  constructor(timePeriodKey, data) {
    this.TimePeriodKey = timePeriodKey
    this.timePeriodKeyValue = data.wartosc
    this.DochodStrata = data.dochodStrata
    this.KosztyUboczneZakupu = data.kosztyUboczneZakupu
    this.KosztyUzyskaniaPrzychodu = data.kosztyUzyskaniaPrzychodu
    this.PodatekDochodowy = data.podatekDochodowy
    this.PozostaleKoszty = data.pozostaleKoszty
    this.PozostaleKosztyAmortyzacja = data.pozostaleKosztyAmortyzacja
    this.PozostaleKosztyCzynsz = data.pozostaleKosztyCzynsz
    this.PozostaleKosztyOdsetki = data.pozostaleKosztyOdsetki
    this.PozostalePrzychody = data.pozostalePrzychody
    this.PrzychodyZeSprzedazy = data.przychodyZeSprzedazy
    this.PrzychodyZeSprzedazyTowarowIUslug =
      data.przychodyZeSprzedazyTowarowIUslug
    this.WartoscRemamentu = data.wartoscRemamentu
    this.WynikFinansowy = data.wynikFinansowy
    this.ZakupTowarowIMaterialow = data.zakupTowarowIMaterialow
  }
}

export const dataMapper = (data) => {
  delete data.spisTresci
  let threeObjectDataArray = []
  let baseAssetsData = data.aktywa.wartosc
  let baseDebtsData = data.pasywa.wartosc
  let baseRzis = data.rzis.wartosc
  delete data.pasywa.wartosc
  delete data.aktywa.wartosc
  delete data.rzis.wartosc
  let assets = [
    new Assets('okresBiezacy', baseAssetsData.okresBiezacy),
    new Assets('okresPoprzedzajacy1', baseAssetsData.okresPoprzedzajacy1),
    new Assets('okresPoprzedzajacy2', baseAssetsData.okresPoprzedzajacy2),
  ]
  let debts = [
    new Debts('okresBiezacy', baseDebtsData.okresBiezacy),
    new Debts('okresPoprzedzajacy1', baseDebtsData.okresPoprzedzajacy1),
    new Debts('okresPoprzedzajacy2', baseDebtsData.okresPoprzedzajacy2),
  ]
  let incomeLosses = [
    new IncomeLosses('okresBiezacy', baseRzis.okresBiezacy),
    new IncomeLosses('okresPoprzedzajacy1', baseRzis.okresPoprzedzajacy1),
    new IncomeLosses('okresPoprzedzajacy2', baseRzis.okresPoprzedzajacy2),
  ]
  Object.keys(data).map((objectKey) => {
    if (Object.keys(data[objectKey]).length === 3) {
      if (
        data[objectKey].wartosc !== '' &&
        data[objectKey].wartosc.constructor != Object &&
        !Array.isArray(data[objectKey].wartosc)
      ) {
        let newSmallObject = new ThreeObjectData(objectKey, data[objectKey])
        threeObjectDataArray.push(newSmallObject)
      }
    }
  })
  // console.log(threeObjectDataArray)
  // console.log(assets)
  // console.log(debts)
  // console.log(incomeLosses)
  return [assets, debts, incomeLosses, threeObjectDataArray]
}
