import React, { useState } from 'react'
import {
  Wrapper,
  StyledTable,
  StyledTableCell,
  StyledTableSum,
  StyledSpan,
} from './LiquidityFactors.styles'

const QualityError = () => {
  return (
    <p className="has-text-danger">
      Wartość pola musi należeć do przedziału od 0 do 2
    </p>
  )
}

const formRegisterOptions = { required: true, min: 0, max: 2 }

export const CompanyQualityFactorsTable = ({
  qualityFactors,
  setQualityFactors,
  isDisabled,
  registerForm,
  formErrors,
  clearError,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [setValidation, Validation] = useState(false)
  return (
    <Wrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>Wyszczególnienie</th>
            <th>Przedział Punktowy</th>
            <th>Ilość przyznanych punktów</th>
            <th>Waga</th>
            <th>Wynik Oceny</th>
            <th>Uzasadnienie Oceny</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell colSpan="3">
            Jakość przedsięwzięcia inwestycyjnego (1+2+3)
          </StyledTableCell>
          <StyledTableCell>{qualityFactors.quality_factor}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            1. Analiza celowości i uwarunkowań rynkowych
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.targeting_analysis_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.targeting_analysis_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('targetingPoints', formRegisterOptions)}
                  type="number"
                  step="0.1"
                  className="input"
                  defaultValue={qualityFactors.targeting_analysis_points}
                  id="targeting_analysis_points"
                  onChange={(event) => {
                    clearError('targetingPoints')
                    qualityFactors.targeting_analysis_points =
                      event.target.value
                    setQualityFactors(qualityFactors)
                  }}
                />
                {formErrors.targetingPoints && <QualityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.targeting_analysis_factor}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.targeting_analysis_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.targeting_analysis_justification
            ) : (
              <textarea
                defaultValue={qualityFactors.targeting_analysis_justification}
                placeholder="Uzasadnienie oceny"
                id="targeting_analysis_justification"
                className="textarea has-fixed-size"
                onChange={(event) => {
                  qualityFactors.targeting_analysis_justification =
                    event.target.value
                  setQualityFactors(qualityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            2. Analiza wykonalności techniczno – organizacyjnej
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.technical_analysis_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.technical_analysis_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.1"
                  {...registerForm('technicalPoints', formRegisterOptions)}
                  className="input"
                  defaultValue={qualityFactors.technical_analysis_points}
                  id="technical_analysis_points"
                  onChange={(event) => {
                    clearError('technicalPoints')
                    qualityFactors.technical_analysis_points =
                      event.target.value
                    setQualityFactors(qualityFactors)
                  }}
                />
                {formErrors.technicalPoints && <QualityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.technical_analysis_factor}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.technical_analysis_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.technical_analysis_justification
            ) : (
              <textarea
                defaultValue={qualityFactors.technical_analysis_justification}
                placeholder="Uzasadnienie oceny"
                id="technical_analysis_justification"
                className="textarea has-fixed-size"
                rows="7"
                onChange={(event) => {
                  qualityFactors.technical_analysis_justification =
                    event.target.value
                  setQualityFactors(qualityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>3. Analiza wykonalności finansowej</StyledTableCell>
          <StyledTableCell>
            {qualityFactors.financial_feasibility_department}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.financial_feasibility_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('feasibility', formRegisterOptions)}
                  type="number"
                  step="0.1"
                  defaultValue={qualityFactors.financial_feasibility_points}
                  className="input"
                  id="financial_feasibility_points"
                  onChange={(event) => {
                    clearError('feasibility')
                    qualityFactors.financial_feasibility_points =
                      event.target.value
                    setQualityFactors(qualityFactors)
                  }}
                />
                {formErrors.feasibility && <QualityError />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.financial_feasibility_factor}
          </StyledTableCell>
          <StyledTableCell>
            {qualityFactors.financial_feasibility_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              qualityFactors.financial_feasibility_justification
            ) : (
              <textarea
                defaultValue={
                  qualityFactors.financial_feasibility_justification
                }
                className="textarea has-fixed-size"
                placeholder="Uzasadnienie oceny"
                id="financial_feasibility_justification"
                rows="10"
                onChange={(event) => {
                  qualityFactors.financial_feasibility_justification =
                    event.target.value
                  setQualityFactors(qualityFactors)
                }}
              />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableSum className="title is-4" colSpan="6">
            Suma punktów:{' '}
            <StyledSpan>{qualityFactors.quality_score}</StyledSpan>
          </StyledTableSum>
        </tr>
      </StyledTable>
    </Wrapper>
  )
}
