import styled from 'styled-components'

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: lightgray;
  width: 100vw;
  height: 100vh;
`
export const DetailWrapper = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  height: calc(100%-10px);
  margin: 10px;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
`
export const SectionWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  height: calc(100%-20px);
  margin: 10px;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  position: relative;
`

export const SectionBar = styled.h1`
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding: 20px;
  background-color: rgba(40, 168, 87, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledList = styled.ul`
  padding: 10px;
`

export const StyledListElement = styled.li`
  margin: 20px 0;
  font-size: 20px;
  list-style: decimal;
  margin-left: 35px;
  > a {
    color: black;
    transition: 0.2s ease-in;

    &:hover {
      color: lightgreen;
      cursor: pointer;
    }
  }
`

export const StyledButton = styled.button`
  display: inline-block;
  margin-left: 10px;
  > a {
    color: black;
  }
`

export const CenterBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const SectionPagination = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  padding: 10px;
  > button {
    margin: 10px;
  }
  justify-content: left;
  position: absolute;
  bottom: 0;
`
