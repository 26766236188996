import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoginWrapper = styled.div`
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  height: 50%;
  width: 25%;

  > label {
    margin: 10px 0;
  }

  > button {
    margin: 10px;
  }
`
