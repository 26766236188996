import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CompanyDetailService from '../../services/CompanyDetailService'
import {
  AnalysisWrapper,
  DetailWrapper,
  SectionBar,
  SectionWrapper,
  Wrapper,
  StyledList,
  StyledListElement,
  CenterBox,
  SectionPagination,
  CompanyText,
} from './CompanyDetail.styles'
import { BsBuilding } from 'react-icons/bs'
import { TailSpin } from 'react-loader-spinner'
import { useCookies } from 'react-cookie'

export const CompanyDetail = () => {
  const { companyId } = useParams()
  const [cookies] = useCookies([])

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()

  const [companyDetails, setDetails] = useState([])
  const [marketAnalysis, setMarketAnalysis] = useState([])
  const [internalProcesses, setInternalProcesses] = useState([])
  const [marketAnalysisLinks, setMarketAnalysisLinks] = useState({
    prev: null,
    next: null,
  })

  const [internalProcessesLinks, setInternalProcessesLinks] = useState({
    prev: null,
    next: null,
  })

  const [marketAnalysisSpinner, setMarketAnalysisSpinner] = useState(true)
  const [internalProcessesSpinner, setInternalProcessesSpinner] = useState(true)

  useEffect(() => {
    setMarketAnalysisSpinner(true)
    setInternalProcessesSpinner(true)
    Promise.all([
      CompanyDetailService.getCompanyDetails(companyId, null, cookies),
      CompanyDetailService.getCompanyMarketAnalyses(companyId, null, cookies),
      CompanyDetailService.getInternalProcesses(companyId, null, cookies),
    ])
      .then(function (apiResults) {
        setDetails(apiResults[0])
        setMarketAnalysis(apiResults[1]['results'])
        setInternalProcesses(apiResults[2]['results'])
        setMarketAnalysisLinks({
          prev: apiResults[1]['previous'],
          next: apiResults[1]['next'],
        })
        setInternalProcessesLinks({
          prev: apiResults[2]['previous'],
          next: apiResults[2]['next'],
        })
        setMarketAnalysisSpinner(false)
        setInternalProcessesSpinner(false)
      })
      .catch(() => {
        Promise.all([
          CompanyDetailService.getCompanyDetails(companyId, null, cookies),
          CompanyDetailService.getCompanyMarketAnalyses(
            companyId,
            null,
            cookies
          ),
          CompanyDetailService.getInternalProcesses(companyId, null, cookies),
        ])
          .then(function (apiResults) {
            setMarketAnalysis(apiResults[1].results)
            setMarketAnalysisLinks({
              prev: apiResults[1]['previous'],
              next: apiResults[1]['next'],
            })
            setInternalProcesses(apiResults[2].results)
            setInternalProcessesLinks({
              prev: apiResults[2]['previous'],
              next: apiResults[2]['next'],
            })
          })
          .catch((error) => {
            console.log(error)
          })
      })
  }, [])

  const changeMarketAnalysisPage = (pageUrl) => {
    setMarketAnalysisSpinner(true)
    CompanyDetailService.getCompanyMarketAnalyses(
      companyId,
      pageUrl,
      cookies
    ).then((result) => {
      setMarketAnalysis(result.results)
      setMarketAnalysisLinks({
        prev: result['previous'],
        next: result['next'],
      })
      setMarketAnalysisSpinner(false)
    })
  }

  const changeInternalProcessesPage = (pageUrl) => {
    setInternalProcessesSpinner(true)
    CompanyDetailService.getInternalProcesses(companyId, pageUrl, cookies).then(
      (result) => {
        setInternalProcesses(result.results)
        setInternalProcessesLinks({
          prev: result['previous'],
          next: result['next'],
        })

        setInternalProcessesSpinner(false)
      }
    )
  }

  return (
    <Wrapper className="block">
      <DetailWrapper>
        <SectionBar>
          {companyDetails.company_name} <BsBuilding width="1000px" />
        </SectionBar>
        <CompanyText>
          <h2 className="title is-4">
            REGON:{' '}
            {companyDetails.company_regon
              ? companyDetails.company_regon
              : 'Brak danych'}
          </h2>
          <h2 className="title is-4">
            NIP:{' '}
            {companyDetails.company_nip
              ? companyDetails.company_nip
              : 'Brak danych'}
          </h2>
          <h2 className="title is-4">
            Adres firmy:{' '}
            {companyDetails.company_address
              ? companyDetails.company_address
              : 'Brak danych'}
          </h2>
          <h2 className="title is-4">
            Adres kontaktowy:{' '}
            {companyDetails.company_contact
              ? companyDetails.company_contact
              : 'Brak danych'}
          </h2>
        </CompanyText>
      </DetailWrapper>
      <AnalysisWrapper>
        <SectionWrapper>
          <SectionBar>
            Stworzone analizy rynkowe:
            <div>
              <Link
                to={`/marketAnalysis/new/${companyId}/`}
                className="button is-light"
              >
                Dodaj analize
              </Link>
              <Link
                to={`/marketAnalysis/compare/${companyId}/`}
                className="button is-light"
                disabled={marketAnalysis.length === 0}
              >
                Porównaj na Tle Innych Firm
              </Link>
            </div>
          </SectionBar>
          {marketAnalysisSpinner ? (
            <CenterBox>
              <TailSpin />
            </CenterBox>
          ) : (
            <StyledList>
              {marketAnalysis.map((analysis) => (
                <StyledListElement key={analysis.pk}>
                  <Link to={`/marketAnalysis/${analysis.pk}/`}>
                    Analiza z dnia {analysis.modified_at}
                  </Link>
                </StyledListElement>
              ))}
            </StyledList>
          )}
          <SectionPagination>
            <button
              className="button is-outlined"
              onClick={() => changeMarketAnalysisPage(marketAnalysisLinks.prev)}
              disabled={marketAnalysisLinks.prev === null}
            >
              Poprzednia strona
            </button>
            <button
              className="button is-outlined"
              onClick={() => changeMarketAnalysisPage(marketAnalysisLinks.next)}
              disabled={marketAnalysisLinks.next === null}
            >
              Następna strona
            </button>
          </SectionPagination>
        </SectionWrapper>
        <SectionWrapper>
          <SectionBar>
            Stworzone Analizy Procesów Wewnętrznych{' '}
            <div>
              <Link
                to={`/internal_processes/new/${companyId}/`}
                className="button is-light"
              >
                Dodaj analize procesów
              </Link>
              <Link
                to={`/internalProcesses/compare/${companyId}/`}
                className="button is-light"
                disabled={internalProcesses.length === 0}
              >
                Porównaj na Tle Innych Firm
              </Link>
            </div>
          </SectionBar>
          {internalProcessesSpinner ? (
            <CenterBox>
              <TailSpin />
            </CenterBox>
          ) : (
            <StyledList>
              {internalProcesses.map((process) => (
                <StyledListElement key={process.pk}>
                  <Link to={`/internal_processes/${process.id}/`}>
                    Proces {internalProcesses.indexOf(process) + 1}
                  </Link>
                </StyledListElement>
              ))}
            </StyledList>
          )}
          <SectionPagination>
            <button
              className="button is-outlined"
              onClick={() =>
                changeInternalProcessesPage(internalProcessesLinks.prev)
              }
              disabled={internalProcessesLinks.prev === null}
            >
              Poprzednia strona
            </button>
            <button
              className="button is-outlined"
              onClick={() =>
                changeInternalProcessesPage(internalProcessesLinks.next)
              }
              disabled={internalProcessesLinks.next === null}
            >
              Następna strona
            </button>
          </SectionPagination>
        </SectionWrapper>
      </AnalysisWrapper>
    </Wrapper>
  )
}
