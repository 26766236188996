import React from 'react'
import {
  Wrapper,
  StyledSpan,
  StyledTable,
  StyledTableCell,
  StyledTableSum,
} from './ObjectiveFactorsTable.styles'

const FormErrorMessage = () => {
  return (
    <p className="has-text-danger">
      Upewnij się, że pole jest wypełnione a także jego wartość mieści się w
      podanych kryteriach
    </p>
  )
}

const FormErrorTextMessage = () => {
  return <p className="has-text-danger">Pole jest wymagane</p>
}

export function ObjectiveFactorsTable({
  objectiveFactors,
  setParentObjectiveFactors,
  isDisabled,
  registerForm,
  formErrors,
  clearErrors,
}) {
  // eslint-disable-next-line no-unused-vars
  const POINT_FOUR = 0.4
  // eslint-disable-next-line no-unused-vars
  const POINT_TWO = 0.2
  // eslint-disable-next-line no-unused-vars
  const POINT_THREE = 0.3

  const getMinValue = (baseValue, difference) => {
    const baseReturn = parseFloat(baseValue) - difference
    if (baseReturn <= 0) {
      return 0
    } else return baseReturn
  }

  const getMaxValue = (baseValue, difference) => {
    const baseReturn = parseFloat(baseValue) + difference
    if (baseReturn >= 2) {
      return 2
    } else return baseReturn
  }

  return (
    <Wrapper className="block">
      <StyledTable class="table">
        <thead>
          <tr>
            <th>Wyszczególnienie</th>
            <th>Za dzień</th>
            <th>
              Wartość <br />
              Wskaźnika
            </th>
            <th>Przedział Punktowy</th>
            <th>
              Ilość przyznanych <br />
              punktów
            </th>
            <th>
              Ilość przyznanych <br />
              punktów przez <br />
              pracownika
            </th>
            <th>Waga</th>
            <th>Wynik Oceny</th>
            <th>Uzasadnienie Oceny</th>
          </tr>
        </thead>
        <tr>
          <StyledTableCell colSpan="6">I. Rentowność (1+2)</StyledTableCell>

          <StyledTableCell>
            {objectiveFactors.profitability_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.profitability_score}
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            1. Rentowność sprzedaży brutto (ROS)%
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.sales_profit_indicator_val_bef}
            <br /> <hr />
            {objectiveFactors.sales_profit_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.sales_profit_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.sales_profit_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {' '}
            {isDisabled ? (
              objectiveFactors.sales_profit_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('salesProfit', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.sales_profit_auto_given_points,
                      POINT_FOUR
                    ),
                    max: getMaxValue(
                      objectiveFactors.sales_profit_auto_given_points,
                      POINT_FOUR
                    ),
                  })}
                  type="number"
                  step="0.01"
                  className="input"
                  defaultValue={
                    objectiveFactors.sales_profit_worker_given_points
                  }
                  id="sales_profit_worker_given_points"
                  onChange={(event) => {
                    clearErrors('salesProfit')
                    objectiveFactors.sales_profit_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.salesProfit && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.sales_profit_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.sales_profit_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.sales_profit_justification
            ) : (
              <textarea
                {...registerForm('salesProfitJustification', {
                  required:
                    objectiveFactors.sales_profit_worker_given_points !==
                    objectiveFactors.sales_profit_auto_given_points,
                })}
                defaultValue={objectiveFactors.sales_profit_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="sales_profit_justification"
                onChange={(event) => {
                  objectiveFactors.sales_profit_justification =
                    event.target.value
                  clearErrors('salesProfitJustification')
                  setParentObjectiveFactors(objectiveFactors)
                }}
                disabled={isDisabled}
              />
            )}
            {formErrors.salesProfitJustification && <FormErrorTextMessage />}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>2. Rentowość kapitału (ROE)%</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.capital_profit_indicator_val_bef}
            <br /> <hr />
            {objectiveFactors.capital_profit_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.capital_profit_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.capital_profit_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.capital_profit_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('capitalProfit', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.capital_profit_auto_given_points,
                      POINT_FOUR
                    ),
                    max: getMaxValue(
                      objectiveFactors.capital_profit_auto_given_points,
                      POINT_FOUR
                    ),
                  })}
                  type="number"
                  step="0.01"
                  defaultValue={
                    objectiveFactors.capital_profit_worker_given_points
                  }
                  id="capital_profit_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('capitalProfit')
                    objectiveFactors.capital_profit_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.capitalProfit && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.capital_profit_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.capital_profit_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.capital_profit_justification
            ) : (
              <textarea
                {...registerForm('capitalProfitJustification', {
                  required:
                    objectiveFactors.capital_profit_worker_given_points !==
                    objectiveFactors.capital_profit_auto_given_points,
                })}
                defaultValue={objectiveFactors.capital_profit_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="capital_profit_justification"
                onChange={(event) => {
                  objectiveFactors.capital_profit_justification =
                    event.target.value
                  setParentObjectiveFactors(objectiveFactors)
                  clearErrors('capitalProfitJustification')
                }}
                disabled={isDisabled}
              />
            )}
            {formErrors.capitalProfitJustification && <FormErrorTextMessage />}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell colSpan="6">
            II. Płynność finansowa (1+2)
          </StyledTableCell>

          <StyledTableCell>{objectiveFactors.liquidity_factor}</StyledTableCell>
          <StyledTableCell>{objectiveFactors.liquidity_score}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>1. Płynność bieżąca (CR)</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.current_liquidity_indicator_bef} <br />
            <hr />
            {objectiveFactors.current_liquidity_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.current_liquidity_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.current_liquidity_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.current_liquidity_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('liquidity', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.current_liquidity_auto_given_points,
                      POINT_FOUR
                    ),
                    max: getMaxValue(
                      objectiveFactors.current_liquidity_auto_given_points,
                      POINT_FOUR
                    ),
                  })}
                  type="number"
                  step="0.01"
                  defaultValue={
                    objectiveFactors.current_liquidity_worker_given_points
                  }
                  id="current_liquidity_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors()
                    objectiveFactors.current_liquidity_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.liquidity && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.current_liquidity_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.current_liquidity_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.current_liquidity_justification
            ) : (
              <textarea
                {...registerForm('currentLiquidityJustification', {
                  required:
                    objectiveFactors.current_liquidity_auto_given_points !==
                    objectiveFactors.current_liquidity_worker_given_points,
                })}
                defaultValue={objectiveFactors.current_liquidity_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="current_liquidity_justification"
                onChange={(event) => {
                  clearErrors('currentLiquidityJustification')
                  objectiveFactors.current_liquidity_justification =
                    event.target.value
                  setParentObjectiveFactors(objectiveFactors)
                }}
              />
            )}
            {formErrors.currentLiquidityJustification && (
              <FormErrorTextMessage />
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>2. Płynność szybka (QR)</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.fast_liquidity_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.fast_liquidity_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.fast_liquidity_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.fast_liquidity_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.fast_liquidity_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('fastLiquidity', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.fast_liquidity_auto_given_points,
                      POINT_FOUR
                    ),
                    max: getMaxValue(
                      objectiveFactors.fast_liquidity_auto_given_points,
                      POINT_FOUR
                    ),
                  })}
                  type="number"
                  step="0.01"
                  defaultValue={
                    objectiveFactors.fast_liquidity_worker_given_points
                  }
                  id="fast_liquidity_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('fastLiquidity')
                    objectiveFactors.fast_liquidity_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.fastLiquidity && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.fast_liquidity_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.fast_liquidity_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.fast_liquidity_justification
            ) : (
              <textarea
                {...registerForm('fastLiquidityJustification', {
                  required:
                    objectiveFactors.fast_liquidity_auto_given_points !==
                    objectiveFactors.fast_liquidity_worker_given_points,
                })}
                defaultValue={objectiveFactors.fast_liquidity_justification}
                placeholder="Uzasadnienie oceny"
                className="textarea has-fixed-size"
                id="fast_liquidity_justification"
                onChange={(event) => {
                  clearErrors('fastLiquidityJustification')
                  objectiveFactors.fast_liquidity_justification =
                    event.target.value
                  setParentObjectiveFactors(objectiveFactors)
                }}
                disabled={isDisabled}
              />
            )}
            {formErrors.fastLiquidityJustification && <FormErrorTextMessage />}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell colSpan="6">
            III. Sprawność działania (1+2+3)
          </StyledTableCell>

          <StyledTableCell>
            {objectiveFactors.efficiency_factor}
          </StyledTableCell>
          <StyledTableCell>{objectiveFactors.efficiency_score}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            1. Rotacja należności w dniach (WRND)
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.receivables_turnover_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.receivables_turnover_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.receivables_turnover_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.receivables_turnover_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.receivables_turnover_worker_given_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('receivablesTurnover', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.receivables_turnover_auto_given_points,
                      POINT_TWO
                    ),
                    max: getMaxValue(
                      objectiveFactors.receivables_turnover_auto_given_points,

                      POINT_TWO
                    ),
                  })}
                  defaultValue={
                    objectiveFactors.receivables_turnover_worker_given_points
                  }
                  id="receivables_turnover_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('receivablesTurnover')
                    objectiveFactors.receivables_turnover_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.receivablesTurnover && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.receivables_turnover_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.receivables_turnover_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.receivables_turnover_justification
            ) : (
              <div>
                <textarea
                  defaultValue={
                    objectiveFactors.receivables_turnover_justification
                  }
                  {...registerForm('receivablesJustification', {
                    required:
                      objectiveFactors.receivables_turnover_worker_given_points !==
                      objectiveFactors.receivables_turnover_auto_given_points,
                  })}
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="receivables_turnover_justification"
                  onChange={(event) => {
                    clearErrors('receivablesJustification')
                    objectiveFactors.receivables_turnover_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.receivablesJustification && (
                  <FormErrorTextMessage />
                )}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>2. Rotacja zapasów w dniach (WRZD)</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.stock_rotation_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.stock_rotation_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.stock_rotation_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.stock_rotation_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.stock_rotation_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('stockRotation', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.stock_rotation_auto_given_points,
                      POINT_TWO
                    ),
                    max: getMaxValue(
                      objectiveFactors.stock_rotation_auto_given_points,
                      POINT_TWO
                    ),
                  })}
                  type="number"
                  defaultValue={
                    objectiveFactors.stock_rotation_worker_given_points
                  }
                  id="stock_rotation_worker_given_points"
                  className="input"
                  step="0.01"
                  onChange={(event) => {
                    clearErrors('stockRotation')
                    objectiveFactors.stock_rotation_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.stockRotation && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.stock_rotation_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.stock_rotation_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.stock_rotation_justification
            ) : (
              <div>
                <textarea
                  {...registerForm('stockRotationJustification', {
                    required:
                      objectiveFactors.stock_rotation_worker_given_points !==
                      objectiveFactors.stock_rotation_auto_given_points,
                  })}
                  defaultValue={objectiveFactors.stock_rotation_justification}
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="stock_rotation_justification"
                  onChange={(event) => {
                    clearErrors('stockRotationJustification')
                    objectiveFactors.stock_rotation_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.stockRotationJustification && (
                  <FormErrorTextMessage />
                )}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            3. Rotacja zobowiązań w dniach (WRZbD)
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.commitment_turnover_indicator_bef}
            <br />
            <hr />
            {objectiveFactors.commitment_turnover_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.commitment_turnover_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.commitment_turnover_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.commitment_turnover_worker_given_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('commitmentTurnover', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.commitment_turnover_auto_given_points,
                      POINT_TWO
                    ),
                    max: getMaxValue(
                      objectiveFactors.commitment_turnover_auto_given_points,
                      POINT_TWO
                    ),
                  })}
                  defaultValue={
                    objectiveFactors.commitment_turnover_worker_given_points
                  }
                  id="commitment_turnover_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('commitmentTurnover')
                    objectiveFactors.commitment_turnover_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.commitmentTurnover && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.commitment_turnover_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.commitment_turnover_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.commitment_turnover_justification
            ) : (
              <div>
                <textarea
                  {...registerForm('commitmentJustification', {
                    required:
                      objectiveFactors.commitment_turnover_worker_given_points !==
                      objectiveFactors.commitment_turnover_auto_given_points,
                  })}
                  defaultValue={
                    objectiveFactors.commitment_turnover_justification
                  }
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="commitment_turnover_justification"
                  onChange={(event) => {
                    clearErrors('commitmentJustification')
                    objectiveFactors.commitment_turnover_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.commitmentJustification && <FormErrorTextMessage />}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell colSpan="6">
            IV. Rotacja zobowiązań w dniach (WRZbD)
          </StyledTableCell>

          <StyledTableCell>{objectiveFactors.debt_factor}</StyledTableCell>
          <StyledTableCell>{objectiveFactors.debt_score}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>1. Zadłużenie aktywów (WZA)</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.debt_assets_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.debt_assets_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.debt_assets_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.debt_assets_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.debt_assets_worker_given_points
            ) : (
              <div className="control">
                <input
                  type="number"
                  step="0.01"
                  {...registerForm('debtAssets', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.debt_assets_auto_given_points,
                      POINT_THREE
                    ),
                    max: getMaxValue(
                      objectiveFactors.debt_assets_auto_given_points,
                      POINT_THREE
                    ),
                  })}
                  defaultValue={
                    objectiveFactors.debt_assets_worker_given_points
                  }
                  id="debt_assets_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('debtAssets')
                    objectiveFactors.debt_assets_worker_given_points =
                      parseFloat(event.target.value)
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.debtAssets && <FormErrorMessage />}
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.debt_assets_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.debt_assets_score}
          </StyledTableCell>

          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.debt_assets_justification
            ) : (
              <div>
                <textarea
                  {...registerForm('debtsAssetsJustification', {
                    required:
                      objectiveFactors.debt_assets_auto_given_points !==
                      objectiveFactors.debt_assets_worker_given_points,
                  })}
                  defaultValue={objectiveFactors.debt_assets_justification}
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="debt_assets_justification"
                  onChange={(event) => {
                    clearErrors('debtsAssetsJustification')
                    objectiveFactors.debt_assets_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                  disabled={isDisabled}
                />
                {formErrors.debtsAssetsJustification && (
                  <FormErrorTextMessage />
                )}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>
            2. Pokrycie majątku trwałego kapitałem stałym (WPMK)
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.asset_coverage_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.asset_coverage_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.asset_coverage_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.asset_coverage_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.asset_coverage_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('assetsCoverage', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.asset_coverage_auto_given_points,
                      POINT_THREE
                    ),
                    max: getMaxValue(
                      objectiveFactors.asset_coverage_auto_given_points,
                      POINT_THREE
                    ),
                  })}
                  type="number"
                  step="0.01"
                  defaultValue={
                    objectiveFactors.asset_coverage_worker_given_points
                  }
                  id="asset_coverage_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('assetsCoverage')
                    objectiveFactors.asset_coverage_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.asset_coverage_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.asset_coverage_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.asset_coverage_justification
            ) : (
              <div>
                <textarea
                  {...registerForm('coverageJustification', {
                    required:
                      objectiveFactors.asset_coverage_worker_given_points !==
                      objectiveFactors.asset_coverage_auto_given_points,
                  })}
                  defaultValue={objectiveFactors.asset_coverage_justification}
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="asset_coverage_justification"
                  onChange={(event) => {
                    clearErrors('coverageJustification')
                    objectiveFactors.asset_coverage_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
                {formErrors.coverageJustification && <FormErrorTextMessage />}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableCell>3. Pokrycie odsetek (WPO)</StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.assets_time_bef}
            <br />
            <hr />
            {objectiveFactors.assets_time_now}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.interest_coverage_indicator_val_bef}
            <br />
            <hr />
            {objectiveFactors.interest_coverage_indicator_val}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.interest_coverage_points_department}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.interest_coverage_auto_given_points}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.interest_coverage_worker_given_points
            ) : (
              <div className="control">
                <input
                  {...registerForm('interestCoverage', {
                    required: true,
                    min: getMinValue(
                      objectiveFactors.interest_coverage_auto_given_points,
                      POINT_THREE
                    ),
                    max: getMaxValue(
                      objectiveFactors.interest_coverage_auto_given_points,
                      POINT_THREE
                    ),
                  })}
                  type="number"
                  step="0.01"
                  defaultValue={
                    objectiveFactors.interest_coverage_worker_given_points
                  }
                  id="interest_coverage_worker_given_points"
                  className="input"
                  onChange={(event) => {
                    clearErrors('interestCoverage')
                    objectiveFactors.interest_coverage_worker_given_points =
                      parseFloat(event.target.value.replace(',', '.'))
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                />
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.interest_coverage_factor}
          </StyledTableCell>
          <StyledTableCell>
            {objectiveFactors.interest_coverage_score}
          </StyledTableCell>
          <StyledTableCell>
            {isDisabled ? (
              objectiveFactors.interest_coverage_justification
            ) : (
              <div>
                <textarea
                  {...registerForm('interestJustification', {
                    required:
                      objectiveFactors.interest_coverage_worker_given_points !==
                      objectiveFactors.interest_coverage_auto_given_points,
                  })}
                  defaultValue={
                    objectiveFactors.interest_coverage_justification
                  }
                  placeholder="Uzasadnienie oceny"
                  className="textarea has-fixed-size"
                  id="interest_coverage_justification"
                  onChange={(event) => {
                    clearErrors('interestJustification')
                    objectiveFactors.interest_coverage_justification =
                      event.target.value
                    setParentObjectiveFactors(objectiveFactors)
                  }}
                  disabled={isDisabled}
                />
                {formErrors.interestJustification && <FormErrorTextMessage />}
              </div>
            )}
          </StyledTableCell>
        </tr>
        <tr>
          <StyledTableSum className="title is-4" colSpan="9">
            Suma punktów: <StyledSpan>{objectiveFactors.points_sum}</StyledSpan>
          </StyledTableSum>
        </tr>
      </StyledTable>
    </Wrapper>
  )
}
