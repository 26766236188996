export class Constants {
  static SALES_PROFIT_WORKER = 'sales_profit_worker_given_points'
  static CAPITAL_PROFIT_WORKER = 'capital_profit_worker_given_points'
  static LIQUIDITY_WORKER = 'current_liquidity_worker_given_points'
  static FAST_LIQUIDITY_WORKER = 'fast_liquidity_worker_given_points'
  static RECEIVABLES_WORKER = 'receivables_turnover_worker_given_points'
  static STOCK_ROTATION_WORKER = 'stock_rotation_worker_given_points'
  static COMMITMENT_WORKER = 'commitment_turnover_worker_given_points'
  static DEBT_WORKER = 'debt_assets_worker_given_points'
  static ASSETS_WORKER = 'asset_coverage_worker_given_points'
  static INTEREST_WORKER = 'interest_coverage_worker_given_points'

  static GLOBAL_API_URL = 'https://analiza.barr.org.pl/api'
}
