import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  width: 45vw;
  height: 93vh;
  background-color: #fafafa;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 10px 5px;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
`

export const ArchiveWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  text-align: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  margin: 10px 5px;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
`

export const StyledTable = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
  border: 2px solid black;
  width: 100%;
  font-size: 17px;

  thead > tr > th {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    padding: 5px;
  }
`

export const StyledTableCell = styled.td`
  border: 1px solid black;
  padding: 10px 5px;
  font-size: 14px;
`

export const StyledCenteredTableCell = styled.td`
  border: 1px solid black;
  padding: 10px 5px;
  text-align: center !important;
`

export const StyledTableCellSingle = styled.td`
  text-align: center !important;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  border: 1px solid black;
  padding: 12px 5px;
`
export const SectionBar = styled.h1`
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding: 20px;
  background-color: rgba(40, 168, 87, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Section = styled.div`
  padding: 15px 10px;
  height: 93%;
`

export const StyledButton = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 20px;
`

const ShowTable = keyframes`
 from {
  height: 0%;
 }
 to {
  height: 100%;
 }
`

export const ToggleTable = styled.div`
  animation: ${ShowTable} 0.5s linear;
`

export const Heading = styled.h2`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  transition: 0.3s ease-out;

  &:hover {
    color: lightgreen;
    cursor: pointer;
  }
`
