import React, { useEffect, useState } from 'react'
import { ApplicationViewConstant as APV } from 'utils/ApplicationViewConstans'
import {
  SectionBar,
  StyledTable,
  StyledTableCell,
  StyledTableCellSingle,
  Wrapper,
  Section,
  ToggleTable,
  StyledButton,
  Heading,
} from './ApplicationsView.styles'
import ApplicationViewService from '../../services/ApplicationViewService'
import { useParams, useNavigate } from 'react-router-dom'
import { AiOutlineFileSearch } from 'react-icons/ai'
import TranslateService from '../../services/TranslateService'
import { TailSpin } from 'react-loader-spinner'
import { CenterBox } from '../home/HomePage.styles'
import { useForm } from 'react-hook-form'
import { cloneDeep, isNil } from 'lodash'
import { useCookies } from 'react-cookie'

const ApplicationJustificationError = () => {
  return (
    <p className="has-text-danger">
      Po dokonaniu zmiany wymagane jest uzasadnienie
    </p>
  )
}

export const ApplicationsView = () => {
  /* eslint-disable no-unused-vars */
  const [initialData, setInitialData] = useState([])
  const [data, setData] = useState([])
  const [assets, setAssets] = useState([])
  const [debts, setDebts] = useState([])
  const [rzis, setRzis] = useState([])
  const [rest, setRest] = useState([])
  const { application_id } = useParams()
  const navigate = useNavigate()

  // const [error, setError] = useState('')

  const [showAssets, setShowAssets] = useState(false)
  const [showDebts, setShowDebts] = useState(false)
  const [showRzis, setShowRzis] = useState(false)
  const [showRest, setShowRest] = useState(false)
  const [showAllTables, setShowAllTables] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [cookies] = useCookies([])

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm()

  const [changedAssets, setChangedAssets] = useState({})
  const [changedDebts, setChangedDebts] = useState({})
  const [changedRzis, setChangedRzis] = useState({})

  const [dataBackup, setDataBackup] = useState([])

  const translateService = new TranslateService()

  const hideAll = () => {
    setShowAssets(false)
    setShowDebts(false)
    setShowRest(false)
    setShowRzis(false)
    setShowAllTables(false)
  }

  const showAll = () => {
    setShowAssets(true)
    setShowDebts(true)
    setShowRest(true)
    setShowRzis(true)
    setShowAllTables(true)
  }

  const revertChanges = (event) => {
    event.preventDefault()
    setChangedRzis({})
    setChangedDebts({})
    setChangedAssets({})
    setIsEditing(false)
    downloadData()
  }

  const downloadData = () => {
    setIsLoading(true)
    ApplicationViewService.fetchApplication(application_id, cookies)
      .then((newData) => {
        setData(newData)
        setAssets(newData[0])
        setDebts(newData[1])
        setRzis(newData[2])
        setRest(newData[3])
        setInitialData(newData[4])
        setDataBackup(cloneDeep(newData[4]))
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        navigate('/home')
      })
  }

  const checkForChanges = (
    previousValue,
    currentValue,
    changesHolder,
    setChangesHolder,
    objectToStore
  ) => {
    if (parseFloat(previousValue) === parseFloat(currentValue)) {
      if (!isNil(changesHolder[objectToStore])) {
        delete changesHolder[objectToStore]
        return
      }
    }
    changesHolder[objectToStore] = true
    console.log(changesHolder)
  }

  const checkIfRequired = (changesHolder) => {
    if (Object.keys(changesHolder).length === 0) {
      return false
    } else return true
  }

  const submitData = () => {
    setIsLoading(true)
    ApplicationViewService.updateApplication(
      application_id,
      initialData,
      cookies
    )
      .then(() => {
        downloadData()
        setIsEditing(false)
        setChangedAssets([])
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (application_id !== undefined) {
      downloadData()
    } else navigate('/home')
  }, [])

  return (
    <Wrapper className="block">
      <SectionBar>
        Przegląd wniosku <AiOutlineFileSearch />
      </SectionBar>
      {delete rest[53]}
      {delete rest[54]}
      {delete rest[55]}
      {delete rest[35]}
      {delete rest[39]}
      {delete rest[42]}
      {isLoading ? (
        <CenterBox>
          <TailSpin />
        </CenterBox>
      ) : (
        <form onSubmit={handleSubmit(submitData)}>
          <Section>
            <div className="field is-grouped">
              <StyledButton
                className="button is-info"
                onClick={(event) => {
                  event.preventDefault()
                  showAllTables ? hideAll() : showAll()
                }}
              >
                {showAllTables ? 'Zwiń Wszystko' : 'Rozwiń Wszystko'}
              </StyledButton>
              {isEditing ? (
                <div className="field is-grouped">
                  <p className="control">
                    <StyledButton type="submit" className="button is-primary">
                      Zatwierdź Zmiany
                    </StyledButton>
                  </p>
                  <p className="control">
                    <StyledButton
                      className="button is-danger"
                      onClick={revertChanges}
                    >
                      Anuluj Zmiany
                    </StyledButton>
                  </p>
                </div>
              ) : (
                <p className="control">
                  <StyledButton
                    className="button is-info"
                    onClick={(event) => {
                      event.preventDefault()
                      setIsEditing(true)
                    }}
                  >
                    Edytuj
                  </StyledButton>
                </p>
              )}
            </div>

            <Heading
              className="title is-4"
              onClick={() => setShowAssets(!showAssets)}
            >
              Aktywa:
            </Heading>
            {showAssets ? (
              <ToggleTable>
                <StyledTable>
                  <tbody>
                    {assets.map((asset) =>
                      Object.keys(asset).map((assetKey) => (
                        <>
                          <tr>
                            {assetKey === APV.TIME_PERIOD ? (
                              <StyledTableCellSingle colSpan={2}>
                                {translateService.instant(asset[assetKey])} :{' '}
                                {asset['timePeriodValue']}
                              </StyledTableCellSingle>
                            ) : null}
                          </tr>
                          <tr key={assetKey}>
                            {assetKey === APV.TIME_PERIOD ||
                            assetKey === APV.TIME_PERIOD_VALUE ? null : (
                              <>
                                <StyledTableCell>
                                  {translateService.instant(assetKey)}
                                </StyledTableCell>
                                {isEditing ? (
                                  <StyledTableCell>
                                    <input
                                      disabled={assetKey === 'timePeriodValue'}
                                      defaultValue={asset[assetKey]}
                                      className="input"
                                      type="number"
                                      onChange={(event) => {
                                        let assetIndex = assets.indexOf(asset)
                                        let lowerCaseKey =
                                          assetKey.charAt(0).toLowerCase() +
                                          assetKey.substring(1)
                                        asset[assetKey] = event.target.value
                                        assets[assetIndex] = asset
                                        setAssets(assets)
                                        initialData['data']['aktywa'][
                                          'wartosc'
                                        ][asset.timePeriod][lowerCaseKey] =
                                          parseFloat(event.target.value)
                                        setInitialData(initialData)
                                        checkForChanges(
                                          dataBackup['data']['aktywa'][
                                            'wartosc'
                                          ][asset.timePeriod][lowerCaseKey],
                                          initialData['data']['aktywa'][
                                            'wartosc'
                                          ][asset.timePeriod][lowerCaseKey],
                                          changedAssets,
                                          setChangedAssets,
                                          `${asset.timePeriod}_${lowerCaseKey}`
                                        )
                                        clearErrors('assetsComment')
                                      }}
                                    />
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell>
                                    {asset[assetKey]}
                                  </StyledTableCell>
                                )}
                              </>
                            )}
                          </tr>
                        </>
                      ))
                    )}
                    <tr>
                      <StyledTableCellSingle colSpan={2}>
                        Komentarz
                      </StyledTableCellSingle>
                    </tr>
                    <tr>
                      <StyledTableCell colSpan={2}>
                        {isEditing ? (
                          <div>
                            <textarea
                              {...register('assetsComment', {
                                required: checkIfRequired(changedAssets),
                              })}
                              defaultValue={
                                initialData['data']['aktywa']['komentarz']
                              }
                              className="textarea"
                              onChange={(event) => {
                                clearErrors('assetsComment')
                                initialData['data']['aktywa']['komentarz'] =
                                  event.target.value
                                setInitialData(initialData)
                              }}
                            />
                            {errors.assetsComment && (
                              <ApplicationJustificationError />
                            )}
                          </div>
                        ) : (
                          initialData['data']['aktywa']['komentarz']
                        )}
                      </StyledTableCell>
                    </tr>
                  </tbody>
                </StyledTable>
              </ToggleTable>
            ) : null}

            <Heading
              className="title is-4"
              onClick={() => setShowDebts(!showDebts)}
            >
              Pasywa:{' '}
            </Heading>
            {showDebts ? (
              <StyledTable>
                <tbody>
                  {debts.map((debt) =>
                    Object.keys(debt).map((debtKey) => (
                      <>
                        <tr>
                          {debtKey === APV.TIME_PERIOD_VALUE ? (
                            <StyledTableCellSingle colSpan="2">
                              {translateService.instant(debtKey)} :{' '}
                              {debt[debtKey]}
                            </StyledTableCellSingle>
                          ) : null}
                        </tr>
                        <tr key={debtKey}>
                          {debtKey === APV.TIME_PERIOD_VALUE ||
                          debtKey === APV.TimePeriodKey ? null : (
                            <>
                              <StyledTableCell>
                                {translateService.instant(debtKey)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {isEditing ? (
                                  <input
                                    disabled={debtKey === 'TimePeriodValue'}
                                    defaultValue={debt[debtKey]}
                                    className="input"
                                    type="number"
                                    onChange={(event) => {
                                      let debtIndex = debts.indexOf(debt)
                                      let lowerCaseKey =
                                        debtKey.charAt(0).toLowerCase() +
                                        debtKey.substring(1)
                                      debt[debtKey] = event.target.value
                                      debts[debtIndex] = debt
                                      setDebts(debts)
                                      initialData['data']['pasywa']['wartosc'][
                                        debt.TimePeriodKey
                                      ][lowerCaseKey] = parseFloat(
                                        event.target.value
                                      )
                                      setInitialData(initialData)
                                      checkForChanges(
                                        dataBackup['data']['pasywa']['wartosc'][
                                          debt.TimePeriodKey
                                        ][lowerCaseKey],
                                        initialData['data']['pasywa'][
                                          'wartosc'
                                        ][debt.TimePeriodKey][lowerCaseKey],
                                        changedDebts,
                                        setChangedDebts,
                                        `${debt.timePeriod}_${lowerCaseKey}`
                                      )
                                    }}
                                  />
                                ) : (
                                  debt[debtKey]
                                )}
                              </StyledTableCell>{' '}
                            </>
                          )}
                        </tr>
                      </>
                    ))
                  )}
                  <tr>
                    <StyledTableCellSingle colSpan={2}>
                      Komentarz
                    </StyledTableCellSingle>
                  </tr>
                  <tr>
                    <StyledTableCell colSpan={2}>
                      {isEditing ? (
                        <div>
                          <textarea
                            {...register('debtsComment', {
                              required: checkIfRequired(changedDebts),
                            })}
                            className="textarea"
                            defaultValue={
                              initialData['data']['pasywa']['komentarz']
                            }
                            onChange={(event) => {
                              clearErrors('debtsComment')
                              initialData['data']['pasywa']['komentarz'] =
                                event.target.value
                              setInitialData(initialData)
                            }}
                          />
                          {errors.debtsComment && (
                            <ApplicationJustificationError />
                          )}
                        </div>
                      ) : (
                        initialData['data']['pasywa']['komentarz']
                      )}
                    </StyledTableCell>
                  </tr>
                </tbody>
              </StyledTable>
            ) : null}

            <Heading
              className="title is-4"
              onClick={() => setShowRzis(!showRzis)}
            >
              Rachunek zysków i strat:{' '}
            </Heading>
            {showRzis ? (
              <StyledTable>
                <tbody>
                  {rzis.map((rzisOne) =>
                    Object.keys(rzisOne).map((rzisKey) => (
                      <>
                        <tr>
                          {rzisKey === APV.TIME_PERIOD_KEY_VALUE ? (
                            <StyledTableCellSingle colSpan="2">
                              {translateService.instant(rzisKey)} :{' '}
                              {rzisOne[rzisKey]}
                            </StyledTableCellSingle>
                          ) : null}
                        </tr>
                        <tr key={rzisKey}>
                          {rzisKey === APV.TIME_PERIOD_KEY_VALUE ||
                          rzisKey === APV.TimePeriodKey ? null : (
                            <>
                              <StyledTableCell>
                                {translateService.instant(rzisKey)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {isEditing ? (
                                  <input
                                    className="input"
                                    type="number"
                                    step="0.1"
                                    defaultValue={rzisOne[rzisKey]}
                                    onChange={(event) => {
                                      let rzisOneIndex = rzis.indexOf(rzisOne)
                                      let lowerCaseKey =
                                        rzisKey.charAt(0).toLowerCase() +
                                        rzisKey.substring(1)
                                      rzisOne[rzisKey] = parseFloat(
                                        event.target.value
                                      )
                                      rzis[rzisOneIndex] = rzisOne
                                      setRzis(rzis)
                                      console.log(
                                        initialData['data']['rzis']['wartosc'],
                                        rzisOne.TimePeriodKey
                                      )
                                      initialData['data']['rzis']['wartosc'][
                                        rzisOne.TimePeriodKey
                                      ][lowerCaseKey] = parseFloat(
                                        event.target.value
                                      )
                                      setInitialData(initialData)
                                      checkForChanges(
                                        dataBackup['data']['rzis']['wartosc'][
                                          rzisOne.TimePeriodKey
                                        ][lowerCaseKey],
                                        initialData['data']['rzis']['wartosc'][
                                          rzisOne.TimePeriodKey
                                        ][lowerCaseKey],
                                        changedRzis,
                                        setChangedRzis,
                                        `${rzisOne.TimePeriodKey}_${lowerCaseKey}`
                                      )
                                    }}
                                  />
                                ) : (
                                  rzisOne[rzisKey]
                                )}
                              </StyledTableCell>
                            </>
                          )}
                        </tr>
                      </>
                    ))
                  )}
                  <tr>
                    <StyledTableCellSingle colSpan={2}>
                      Komentarz
                    </StyledTableCellSingle>
                  </tr>
                  <tr>
                    <StyledTableCell colSpan={2}>
                      {isEditing ? (
                        <div>
                          <textarea
                            {...register('rzisComment', {
                              required: checkIfRequired(changedRzis),
                            })}
                            className="textarea"
                            defaultValue={
                              initialData['data']['rzis']['komentarz']
                            }
                            onChange={(event) => {
                              clearErrors('rzisComment')
                              initialData['data']['rzis']['komentarz'] =
                                event.target.value
                              setInitialData(initialData)
                            }}
                          />
                          {errors.rzisComment && (
                            <ApplicationJustificationError />
                          )}
                        </div>
                      ) : (
                        initialData['data']['rzis']['komentarz']
                      )}
                    </StyledTableCell>
                  </tr>
                </tbody>
              </StyledTable>
            ) : null}
            <Heading
              className="title is-4"
              onClick={() => setShowRest(!showRest)}
            >
              Pozostałe Wartości:{' '}
            </Heading>
            {showRest ? (
              <StyledTable>
                <tbody>
                  {rest.map((restOne) =>
                    Object.keys(restOne).map((restKey) => (
                      <>
                        <tr>
                          {restKey === APV.NAME ? (
                            <StyledTableCellSingle colSpan={2}>
                              {translateService.instant(restOne[restKey])}
                            </StyledTableCellSingle>
                          ) : null}
                        </tr>
                        <tr key={restOne}>
                          {restKey === APV.NAME ? null : (
                            <>
                              <StyledTableCell>
                                {restKey === APV.VALUE
                                  ? 'Wartość'
                                  : restKey === APV.COMMENT
                                  ? 'Komentarz'
                                  : 'Czy Gotowy'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {restOne[restKey]}
                              </StyledTableCell>
                            </>
                          )}
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </StyledTable>
            ) : null}
          </Section>
        </form>
      )}
    </Wrapper>
  )
}
