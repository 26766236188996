import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  border: 2px solid black;
  width: 100%;
  font-size: 15px;

  > thead > tr > th {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    padding: 5px;
  }

  textarea {
    height: 100%;
    width: 100%;
  }
`

export const StyledTableCell = styled.td`
  border: 1px solid black;
  padding: 5px;
  vertical-align: center;
`
