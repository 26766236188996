// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import { Constants } from '../utils/Constants'

export const getAuthHeaders = (cookies) => {
  // if (isNil(cookies['session-id'])) return null
  const sessionCookie = cookies['token']
  const userId = cookies['userID']
  return `{ 'token': '${sessionCookie}', 'userID': '${userId}' }`
}

export const performLogin = (login, password) => {
  const loginObject = {
    email: login,
    password: password,
  }
  return axios.post(`${Constants.GLOBAL_API_URL}/login/`, loginObject)
}

export const performLogout = (cookies) => {
  const sessioToken = cookies['token']
  const userID = cookies['userID']
  const logoutObject = { token: sessioToken, user_id: userID }
  return axios.post(`${Constants.GLOBAL_API_URL}/logout/`, logoutObject)
}

export const getAuthQueryParams = (cookies, isStart = false) => {
  const sessionCookie = cookies['token']
  const userId = cookies['userID']
  return `${isStart ? '?' : '&'}t=${sessionCookie}&uid=${userId}`
}

export const authorize = (cookies) => {
  return axios.get(`${Constants.GLOBAL_API_URL}/authorize/`, {
    headers: {
      Authorization: getAuthHeaders(cookies),
    },
  })
}
