import React, { useEffect, useState } from 'react'
import {
  Wrapper,
  StyledButton,
  ToggleTable,
  Heading,
  SectionBar,
  Section,
} from './ApplicationsSyntheticEvaluation.styles'
import { ObjectiveFactorsTable } from 'components/tables/ObjectiveFactorsTable/ObjectiveFactorsTable'
import { SubjectiveFactorsTable } from '../../components/tables/SubjectiveFactorsTable/SubjectiveFactorsTable'
import { LiquidityFactorsTable } from '../../components/tables/LiquidityFactorsTable/LiquidityFactorsTable'
import { SafeguardsTable } from '../../components/tables/SafeguardsTable/SafeguardsTable'
import SyntheticEvaluationService from '../../services/SyntheticEvaluationService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Icons
import { FaCheck } from 'react-icons/fa'
import { GiCancel } from 'react-icons/gi'
import { TbFileCheck } from 'react-icons/tb'
import {
  BsFillArrowUpSquareFill,
  BsFillArrowDownSquareFill,
} from 'react-icons/bs'
import { SummaryTable } from '../../components/tables/SummaryTable/SummaryTable'
import { ManagerSummaryTable } from '../../components/tables/SummaryTable/ManagerSummaryTable'
import { useForm } from 'react-hook-form'
// import { Constants } from 'utils/Constants'
import { CompanyQualityFactorsTable } from '../../components/tables/LiquidityFactorsTable/QualityFactorsTable'
import { CenterBox } from '../MarketAnalysis/MarketAnalysis.styles'
import { TailSpin } from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { saveAs } from 'file-saver'
export const ApplicationsSyntheticEvaluation = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [reportData, setReportData] = useState([])
  const [objectiveFactors, setObjectiveFactors] = useState([])
  const [subjectiveFactors, setSubjectiveFactors] = useState([])
  const [companyLiquidity, setCompanyLiquidity] = useState([])
  const [companyQuality, setCompanyQuality] = useState([])
  const [safeGuards, setSafeGuards] = useState([])
  const [isLiquidity, setIsLiquidity] = useState(false)
  const [summary, setSummary] = useState({})

  // Pokazywanie tabel

  const [showAllTables, setShowAllTables] = useState(false)
  const [showObjectiveFactors, setShowObjectiveFactors] = useState(false)
  const [showSubjectiveFactors, setShowSubjectiveFactors] = useState(false)
  const [showLiquidityFactors, setShowLiquidityFactors] = useState(false)
  const [showSafeguards, setShowSafeguards] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [showManagerSummary, setShowManagerSummary] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isWorkerRated, setIsWorkerRated] = useState(false)

  const { application_id } = useParams()

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm()
  const query = useQuery()
  const navigate = useNavigate()
  const isManager = query.get('m') === 'true'
  const [cookies] = useCookies()

  useEffect(() => {
    if (application_id !== undefined) {
      SyntheticEvaluationService.getReport(
        application_id,
        query.get('m'),
        cookies
      )
        .then((data) => {
          setReportComponents(data)
        })
        .catch((error) => {
          console.log(error)
          navigate('/home')
        })
    }
  }, [])

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const prepareReportBody = () => {
    setIsSaving(true)
    reportData.objective_factors_eval = objectiveFactors
    reportData.subjective_factors_eval = subjectiveFactors
    reportData.company_liquidity_eval = companyLiquidity
    reportData.safeguards = safeGuards
    reportData.applicant_rating = summary.applicant_rating
    reportData.safeguards_level = summary.safeguards_level
    reportData.company_quality_eval = companyQuality
    setReportData(reportData)
    console.log(reportData)
    SyntheticEvaluationService.editReport(
      reportData,
      reportData['application_id'],
      isManager,
      cookies
    )
      .then((result) => {
        setIsDisabled(true)
        setIsSaving(false)
        setReportComponents(result.data)
      })
      .catch((error) => {
        setIsSaving(false)
        console.log(error)
        alert(
          'Błąd zapisu. Upewnij się, że wsyzstkie pola zostały poprawnie wypełnione'
        )
      })
  }

  const setReportComponents = (data) => {
    setReportData(data)
    setIsLiquidity(data.is_liquidity)
    setIsWorkerRated(data.is_worker_rated)
    setSummary({
      applicant_rating: data.applicant_rating,
      points_sum: data.points_sum,
      safeguards_score: data.safeguards_score,
      safeguards_level: data.safeguards_level,
      other_evaluator_points_sum: data.other_evaluator_points_sum,
      points_average: data.points_average,
      loan_margin: data.loan_margin,
      other_evaluator_safeguards_level: data.other_evaluator_safeguards_level,
    })
    setObjectiveFactors(data.objective_factors_eval)
    setSubjectiveFactors(data.subjective_factors_eval)
    setCompanyLiquidity(data.company_liquidity_eval)
    setCompanyQuality(data.company_quality_eval)
    setSafeGuards(data.safeguards)
    setIsLoading(false)
  }

  const showAll = () => {
    setShowAllTables(true)
    setShowObjectiveFactors(true)
    setShowSubjectiveFactors(true)
    setShowLiquidityFactors(true)
    setShowSafeguards(true)
    setShowSummary(true)
    setShowManagerSummary(true)
  }

  const hideAll = () => {
    setShowAllTables(false)
    setShowObjectiveFactors(false)
    setShowSubjectiveFactors(false)
    setShowLiquidityFactors(false)
    setShowSafeguards(false)
    setShowSummary(false)
    setShowManagerSummary(false)
  }

  const revertChanges = () => {
    setIsLoading(true)
    setIsDisabled(true)
    clearErrors()
    SyntheticEvaluationService.getReport(
      application_id,
      query.get('m'),
      cookies
    )
      .then((data) => {
        setReportComponents(data)
      })
      .catch(() => navigate('/home'))
  }

  const downloadFile = () => {
    setIsDownloading(true)
    SyntheticEvaluationService.downloadReport(reportData['id'], cookies)
      .then((response) => {
        saveAs(
          response.data,
          `${
            reportData.application_company_name !== null
              ? reportData.application_company_name +
                '- analiza syntetyczna.xlsx'
              : 'excel-report.xlsx'
          }`
        )
        setIsDownloading(false)
      })
      .catch(() => {
        setIsDownloading(false)
        alert('Błąd Pobierania Pliku')
      })
  }

  return (
    <Wrapper className="block">
      <SectionBar>
        Ocena syntetyczna Wniosku
        <TbFileCheck />
      </SectionBar>
      {isLoading ? (
        <CenterBox>
          <TailSpin />
        </CenterBox>
      ) : (
        <Section>
          <form onSubmit={handleSubmit(prepareReportBody)}>
            <StyledButton
              className="button is-info"
              onClick={(event) => {
                event.preventDefault()
                showAllTables ? hideAll() : showAll()
              }}
            >
              {showAllTables ? 'Zwiń Wszystko' : 'Rozwiń Wszystko'}
            </StyledButton>
            {isDisabled ? (
              <StyledButton
                className="button is-info"
                onClick={(event) => {
                  event.preventDefault()
                  setObjectiveFactors(objectiveFactors)
                  setIsDisabled(!isDisabled)
                }}
              >
                Edytuj Wniosek
              </StyledButton>
            ) : (
              <StyledButton
                className="button is-danger"
                onClick={(event) => {
                  event.preventDefault()
                  revertChanges()
                }}
              >
                <GiCancel /> Anuluj Zmiany
              </StyledButton>
            )}
            {isDisabled ? null : (
              <StyledButton
                className={`button is-primary ${
                  isSaving ? 'is-loading' : null
                }`}
                hidden={isDisabled}
              >
                <FaCheck /> Zatwierdź Edycję
              </StyledButton>
            )}
            {isDisabled ? null : isWorkerRated ? (
              <StyledButton
                className="button is-danger"
                onClick={(event) => {
                  event.preventDefault()
                  reportData['is_worker_rated'] = false
                  setIsWorkerRated(false)
                  setReportData(reportData)
                }}
              >
                <GiCancel /> Oznacz Jako Nieoceniony
              </StyledButton>
            ) : (
              <StyledButton
                className="button is-primary"
                onClick={(event) => {
                  event.preventDefault()
                  reportData['is_worker_rated'] = true
                  setIsWorkerRated(true)
                  setReportData(reportData)
                }}
              >
                <FaCheck />
                Oznacz Jako Oceniony
              </StyledButton>
            )}
            <StyledButton
              className={`button is-link ${
                isDownloading ? 'is-loading' : null
              }`}
              onClick={(event) => {
                event.preventDefault()
                downloadFile()
              }}
            >
              Pobierz Raport
            </StyledButton>
            {isManager && isDisabled ? (
              <div>
                <Heading
                  className="title is-4"
                  onClick={() => setShowManagerSummary(!showManagerSummary)}
                >
                  Podsumowanie Karty Oceny - Wartości Średnie{' '}
                  {showSummary ? (
                    <BsFillArrowUpSquareFill />
                  ) : (
                    <BsFillArrowDownSquareFill />
                  )}
                </Heading>
                {showManagerSummary ? (
                  <ToggleTable>
                    <ManagerSummaryTable summary={summary} />
                  </ToggleTable>
                ) : null}
              </div>
            ) : null}
            <Heading
              className="title is-4"
              onClick={() => setShowSummary(!showSummary)}
            >
              Podsumowanie Karty Oceny{' '}
              {showSummary ? (
                <BsFillArrowUpSquareFill />
              ) : (
                <BsFillArrowDownSquareFill />
              )}
            </Heading>
            {showSummary ? (
              <ToggleTable>
                <SummaryTable
                  summary={summary}
                  setSummary={setSummary}
                  isDisabled={isDisabled}
                />
              </ToggleTable>
            ) : null}
            <Heading
              className="title is-4"
              onClick={() => setShowObjectiveFactors(!showObjectiveFactors)}
            >
              Ocena Czynników Obiektywnych{' '}
              {showObjectiveFactors ? (
                <BsFillArrowUpSquareFill />
              ) : (
                <BsFillArrowDownSquareFill />
              )}
            </Heading>
            {showObjectiveFactors ? (
              <ToggleTable>
                <ObjectiveFactorsTable
                  objectiveFactors={objectiveFactors}
                  setParentObjectiveFactors={setObjectiveFactors}
                  isDisabled={isDisabled}
                  registerForm={register}
                  formErrors={errors}
                  clearErrors={clearErrors}
                />
              </ToggleTable>
            ) : null}
            <Heading
              className="title is-4"
              onClick={() => setShowSubjectiveFactors(!showSubjectiveFactors)}
            >
              Ocena Czynników Subiektywnych
              {showSubjectiveFactors ? (
                <BsFillArrowUpSquareFill />
              ) : (
                <BsFillArrowDownSquareFill />
              )}
            </Heading>
            {showSubjectiveFactors ? (
              <SubjectiveFactorsTable
                subjectiveFactors={subjectiveFactors}
                setSubjectiveFactors={setSubjectiveFactors}
                isDisabled={isDisabled}
                registerForm={register}
                formErrors={errors}
                clearErrors={clearErrors}
              />
            ) : null}
            <Heading
              className="title is-4"
              onClick={() => setShowLiquidityFactors(!showLiquidityFactors)}
            >
              {isLiquidity
                ? 'Ocena Płynności Finansowej'
                : 'Ocena Jakości Przedsięwzięcia Inwestycyjnego'}
              {showLiquidityFactors ? (
                <BsFillArrowUpSquareFill />
              ) : (
                <BsFillArrowDownSquareFill />
              )}
            </Heading>
            {showLiquidityFactors ? (
              isLiquidity ? (
                <LiquidityFactorsTable
                  isDisabled={isDisabled}
                  liquidityFactors={companyLiquidity}
                  setLiquidityFactors={setCompanyLiquidity}
                  registerForm={register}
                  formErrors={errors}
                  clearError={clearErrors}
                />
              ) : (
                <CompanyQualityFactorsTable
                  qualityFactors={companyQuality}
                  setQualityFactors={setCompanyQuality}
                  isDisabled={isDisabled}
                  registerForm={register}
                  formErrors={errors}
                  clearError={clearErrors}
                />
              )
            ) : null}
          </form>
          <Heading
            className="title is-4"
            onClick={() => setShowSafeguards(!showSafeguards)}
          >
            Zabezpieczenia
            {showSafeguards ? (
              <BsFillArrowUpSquareFill />
            ) : (
              <BsFillArrowDownSquareFill />
            )}
          </Heading>
          {showSafeguards ? (
            <SafeguardsTable
              isDisabled={isDisabled}
              parentSafeguards={safeGuards}
              setParentSafeguards={setSafeGuards}
            />
          ) : null}
        </Section>
      )}
    </Wrapper>
  )
}
