import React, { useEffect, useState } from 'react'
import {
  StyledCenteredTableCell,
  StyledTable,
  StyledTableCell,
  Wrapper,
} from './SafeguardsTable.styles'
import { GiCancel } from 'react-icons/gi'
import { GrAdd } from 'react-icons/gr'
import { BiEdit } from 'react-icons/bi'
import { BsFillTrashFill } from 'react-icons/bs'
import { SafeguardsDynamicRow } from './SafeguardsDynamicRow'
import { useForm } from 'react-hook-form'

export const SafeguardsTable = ({
  parentSafeguards,
  setParentSafeguards,
  isDisabled,
}) => {
  const [safeguards, setSafeguards] = useState(parentSafeguards)
  const [safeguardsData, setSafeguardsData] = useState([])
  const [addingSafeguards, setAddingSafeguards] = useState(false)
  const [editedObject, setEditedObject] = useState({ result: 0 })

  let isEditing = false

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    reset,
    setValue,
  } = useForm()

  useEffect(() => {
    setSafeguards(parentSafeguards)
    setSafeguardsData([...parentSafeguards.safeguards])
  }, [])

  const submitData = () => {
    if (isEditing) {
      setAddingSafeguards(false)
      isEditing = false
    }
    editedObject.result = editedObject.safeguard_value / editedObject.loan_value
    safeguardsData.push(editedObject)
    setSafeguardsData(safeguardsData)
    safeguards.safeguards = safeguardsData
    setSafeguards(safeguards)
    setParentSafeguards(safeguards)
    setEditedObject({ result: 0 })
    reset()
    setValue('description', null)
    setValue('value', null)
    setValue('loan', null)
  }

  const prepareEdit = (safeguardIndex) => {
    isEditing = true
    const edited = safeguardsData[safeguardIndex]
    safeguardsData.splice(safeguardIndex, 1)
    setSafeguardsData([...safeguardsData])
    safeguards.safeguards = safeguardsData
    console.log(safeguardsData)
    setSafeguards(safeguards)
    setAddingSafeguards(true)
    setValue('description', edited['description'])
    setValue('value', edited['safeguard_value'])
    setValue('loan', edited['loan_value'])
    setEditedObject(edited)
  }

  const deleteRow = (safeguardIndex) => {
    safeguardsData.splice(safeguardIndex, 1)
    console.log(safeguardsData)
    setSafeguardsData([...safeguardsData])
    safeguards.safeguards = safeguardsData
    setSafeguards(safeguards)
    setParentSafeguards(safeguards)
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(submitData)}>
        <StyledTable>
          <thead>
            <tr>
              <th>Opis</th>
              <th>Wartość Zabezpieczenia</th>
              <th>Wartość Pożyczki Wraz z Odsetkami</th>
              <th>Wksaźnik Zabezpieczeń</th>
              {!isDisabled ? (
                <StyledCenteredTableCell className="container">
                  <button
                    hidden={!isDisabled}
                    className="button is-text"
                    onClick={(event) => {
                      event.preventDefault()
                      setAddingSafeguards(!addingSafeguards)
                    }}
                  >
                    {addingSafeguards ? <GiCancel /> : <GrAdd />}
                  </button>
                </StyledCenteredTableCell>
              ) : null}
            </tr>
          </thead>
          {safeguardsData !== null
            ? safeguardsData.map((safeguard) => {
                return (
                  <tr key={safeguard.safeguard_value + safeguard.loan_value}>
                    <StyledTableCell>{safeguard.description}</StyledTableCell>
                    <StyledTableCell>
                      {safeguard.safeguard_value}
                    </StyledTableCell>
                    {!isDisabled && safeguardsData.indexOf(safeguard) === 0 ? (
                      <StyledTableCell>
                        <input
                          className="input"
                          {...register('in_blanco_loan_value', {
                            required: true,
                          })}
                          defaultValue={safeguard.loan_value}
                          onChange={(event) => {
                            safeguard['loan_value'] = parseFloat(
                              event.target.value
                            )
                            safeguardsData[0] = safeguard
                            setSafeguardsData(safeguardsData)
                          }}
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>{safeguard.loan_value}</StyledTableCell>
                    )}
                    <StyledTableCell>
                      {(parseFloat(safeguard.result) * 100).toFixed(2) + '%'}
                    </StyledTableCell>
                    {!isDisabled ? (
                      <StyledCenteredTableCell className="container">
                        {safeguardsData.indexOf(safeguard) !== 0 ? (
                          <div className="field is-grouped">
                            <p className="control">
                              <button
                                className="button is-text"
                                onClick={(event) => {
                                  event.preventDefault()
                                  deleteRow(safeguardsData.indexOf(safeguard))
                                }}
                              >
                                <BsFillTrashFill />
                              </button>
                            </p>
                            <p className="control">
                              <button
                                className="button is-text"
                                onClick={(event) => {
                                  event.preventDefault()
                                  prepareEdit(safeguardsData.indexOf(safeguard))
                                }}
                              >
                                <BiEdit />
                              </button>
                            </p>
                          </div>
                        ) : null}
                      </StyledCenteredTableCell>
                    ) : null}
                  </tr>
                )
              })
            : null}
          {addingSafeguards && !isDisabled ? (
            <SafeguardsDynamicRow
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              editedObject={editedObject}
              setEditedObject={setEditedObject}
            />
          ) : null}
        </StyledTable>
      </form>
    </Wrapper>
  )
}
