import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: auto auto;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
  border-radius: 10px;
  max-width: 1440px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 400px;
`

export const SectionBar = styled.h1`
  position: sticky;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
  background-color: rgba(40, 168, 87, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`

export const ContentWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 5px;
`

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div > select {
    width: 250px;
  }
`

export const CompaniesList = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 15px;
    font-weight: bold;
  }

  > ul {
    list-style-type: disc;
    li {
      margin-left: 20px;
    }
  }
`
