import React, { useEffect, useState } from 'react'
import {
  CenterBox,
  DetailWrapper,
  HomeWrapper,
  SectionBar,
  SectionPagination,
  StyledButton,
  StyledList,
  StyledListElement,
} from './ApplicationsArchive.styles'
import { AiOutlineFile } from 'react-icons/ai'
import { TailSpin } from 'react-loader-spinner'
import HomePageService from '../../services/HomePageService'
import { useCookies } from 'react-cookie'
import { Link, useNavigate } from 'react-router-dom'

export const ApplicationsArchive = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [allApplications, setAllApplications] = useState([])
  const [additionalLinks, setAdditionalLinks] = useState({
    prev: null,
    next: null,
  })
  const [cookies] = useCookies(['session-id'])
  const navigate = useNavigate()

  useEffect(() => {
    HomePageService.getFiledApplications(null, true, false, true, cookies)
      .then((result) => setBody(result, true))
      .catch(() => {
        HomePageService.getFiledApplications(null, true, false, false, cookies)
          .then((result) => setBody(result, false))
          .catch((error) => {
            console.log(error)
            navigate('/home')
          })
      })
  }, [])

  const setBody = (result, isManager) => {
    setIsLoading(false)
    setIsManager(isManager)
    setAllApplications(result.results)
    setAdditionalLinks({ prev: result.previous, next: result.next })
  }

  const changePage = (additionalUrl) => {
    setIsLoading(true)
    HomePageService.getFiledApplications(
      additionalUrl,
      isManager,
      false,
      true,
      cookies
    )
      .then((result) => setBody(result, isManager))
      .catch((error) => console.log(error))
  }

  return (
    <HomeWrapper className="block">
      <DetailWrapper>
        <SectionBar>
          Archiwum Wszystkich Wniosków <AiOutlineFile />{' '}
        </SectionBar>
        {isLoading ? (
          <CenterBox>
            <TailSpin />
          </CenterBox>
        ) : (
          <StyledList>
            {allApplications.map((application) => (
              <StyledListElement key={application.application_id}>
                <div className="field is-grouped">
                  <p>
                    {new Date(application.creation_date).toDateString()}{' '}
                    {application.type} - {application.status}
                  </p>
                </div>
                <p>
                  <div className="field is-grouped">
                    <p className="control">
                      <StyledButton className="button  is-primary is-small">
                        <Link
                          to={`/syntheticEvaluation/${application.application_id}?m=false`}
                        >
                          Analiza Pracownika
                        </Link>
                      </StyledButton>
                      {isManager ? (
                        <StyledButton className="button  is-warning is-small">
                          <Link
                            to={`/syntheticEvaluation/${application.application_id}?m=true`}
                          >
                            Analiza Kierownika
                          </Link>
                        </StyledButton>
                      ) : null}
                    </p>
                  </div>
                </p>
              </StyledListElement>
            ))}
          </StyledList>
        )}
        <SectionPagination>
          <SectionPagination>
            <button
              className="button is-outlined"
              onClick={() => changePage(additionalLinks.prev)}
              disabled={additionalLinks.prev === null}
            >
              Poprzednia strona
            </button>
            <button
              className="button is-outlined"
              onClick={() => changePage(additionalLinks.next)}
              disabled={additionalLinks.next === null}
            >
              Następna strona
            </button>
          </SectionPagination>
        </SectionPagination>
      </DetailWrapper>
    </HomeWrapper>
  )
}
