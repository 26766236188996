import React, { useState } from 'react'
import { Wrapper, LoginWrapper } from './LoginPage.styles'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { useCookies } from 'react-cookie'
import { performLogin } from '../../services/AuthService'
import { useNavigate } from 'react-router-dom'

export const LoginPage = ({ setIsLoggedIn }) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookies] = useCookies([])

  const [login, setLogin] = useState(null)
  const [password, setPassword] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const navigate = useNavigate()

  const authorize = () => {
    setIsSaving(true)
    performLogin(login, password)
      .then((result) => {
        setIsSaving(false)
        setCookies('token', result.data['token'], { path: '/' })
        setCookies('userID', result.data['user_id'], { path: '/' })
        setIsLoggedIn(true)
        navigate('/analiza/home/')
      })
      .catch((error) => {
        console.log(error)
        alert('Logowanie nie powiodło się, spróbuj jeszcze raz')
        setIsSaving(false)
      })
  }

  return (
    <Wrapper>
      <LoginWrapper>
        <Logo width="80px" height="100px" />
        <h1 className="subtitle is-3">Zaloguj się</h1>
        <label htmlFor="login">
          Login:
          <input
            type="text"
            id="login"
            className="input"
            required
            defaultValue={login}
            onChange={(event) => {
              setLogin(event.target.value)
            }}
          />
        </label>
        <label htmlFor="password">
          Hasło:{' '}
          <input
            type="password"
            id="password"
            className="input"
            required
            onChange={(event) => {
              setPassword(event.target.value)
            }}
            defaultValue={password}
          />
        </label>

        <button
          type="submit"
          className={`button is-primary ${isSaving ? 'is-loading' : null}`}
          onClick={authorize}
        >
          Zaloguj się
        </button>
      </LoginWrapper>
    </Wrapper>
  )
}
